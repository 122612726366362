const ES = {
    brand: 'jdescuentos',
    symbol: '$',
    timeZone: 'America/Mexico_City',
    feedDescription:
        'Jdescuentos es la comunidad #1 en Chile de Cupones, Ofertas &amp; Descuentos. 100% Gratis. Actualizado cada día.',
    routes: {
        subscribe: 'subscribe',
        unsubscribe: 'unsubscribe',
        activity: 'actividad',
        awards: 'premios',
        blog: 'blog',
        brands: 'cupones-descuento',
        categories: 'promociones',
        chat: 'discusion',
        chats: 'chats',
        coins: 'monedas',
        commented: 'comentados',
        contact: 'contacto',
        coupon: 'cupon',
        coupons: 'cupones',
        dashboard: 'dashboard',
        discounts: 'descuentos',
        events: 'eventos',
        changePassword: 'cambiar-contrasena',
        forgotPassword: 'recuperar-password',
        forum: 'discusiones',
        free: 'gratis',
        inbox: 'mensajes',
        login: 'entrar',
        lotteries: 'sorteos',
        lottery: 'sorteo',
        news: 'nuevos',
        notifications: 'notificaciones',
        novelties: 'novedades',
        offer: 'oferta',
        offers: 'ofertas',
        register: 'registro',
        rewards: 'premios',
        saved: 'ofertas-que-sigo',
        settings: 'configuracion',
        share: 'compartir',
        create: 'crear',

        preferences: 'preferencias',
        recovery: 'cambiar-contrasena',
        successChangedPassword: 'contrasena-actualizada-exitosamente',
        expired: 'expirados',
        extension: 'extension',
        extensionUninstalled: 'extension-desinstalada',
        ended: 'finalizado',
        verifyAccount: 'verificar-cuenta',
        search: 'search',
        newPassword: 'nueva-contrasena',
        loginWithoutPass: 'inicio-sin-contrasena',

        rules: 'reglas-del-sitio',
        help: 'ayuda',
        about_us: 'acerca-de-nosotros',
        terms: 'terminos-de-uso',
        privacy: 'politica-de-privacidad',
        cookies: 'cookies',
        legal: 'terminos-de-uso',
        press: 'prensa',
        faq: 'faq',
    },
    components: {
        ctaEMail: {
            title: 'Recibe todos los días los',
            title_2: 'mejores descuentos',
            title_3: 'en tu correo',
            subtitle: '¡Cada día nuevas ofertas & cupones',
            subtitle_2: '100% gratis!',
            placeholder: 'Ingresa tu correo electrónico aquí..',
            button: 'Registrarme',
            success: 'Email suscrito correctamente.',
            failed: 'El email ya se encuentra suscrito.',
            error: 'Ocurrio un error',
            insertEmail: 'Ingrese un correo electrónico',
        },

        ctaExtAndApp: {
            url: 'https://chrome.google.com/webstore/detail/megadescuentos/gkochegofmljpmgegphbcljdigenjlhd?hl=es&authuser=5',
            extHead: 'Instala gratis nuestra',
            extTitle: 'extensión',
            extDescription: 'Buscador automático de cupones y ofertas',
            appHead: 'Descarga',
            appTitle: 'nuestra app',
        },

        ctaSocial: {
            notification: 'Notificación',
            notification_text: '¿Quieres avisos GRATIS de las mejores ofertas vía',
            notification_text_2: {
                facebook: 'Facebook?',
                telegram: 'Telegram?',
                whatsapp: 'Whatsapp?',
            },
            team: 'Equipo Jdescuentos',
            accept: '¡Sí, acepto!',
            url: {
                telegram: 'https://t.me/jdescuentos',
                facebook: 'https://www.facebook.com/groups/710058077386583',
                whatsapp: 'https://chat.whatsapp.com/CnJYHYTDn4mCOSMV8hTVKJ',
            },
        },

        bannerHomepage: {
            welcome: 'Bienvenido a Jdescuentos, soy Jotah', // ok
            signup: 'Regístrate gratis y consigue muchas sorpresas', // ok
        },

        sidebarUserRanking: {
            rank: 'Ranking', // ok
            weekly: 'Semanal', // ok
            monthly: 'Mensual', // ok
            comments: 'Comentarios', // ok
            discounts: 'Descuentos', // ok
        },

        microArcLinks: {
            discounts: 'Descuentos', // ok
        },

        sidebarBanners: {
            publicity: 'Publicidad', // ok
        },

        cardMainGlobalFeatured: {
            published_by: 'Por', // ok
            ago: 'Hace', // ok
            offer_big: 'OFERTA', // ok
            coupon_big: 'CUPÓN', // ok
            more: 'Mas de', // ok
            okay: 'De acuerdo', // ok
            free_ship: 'ENVÍO GRATIS', // ok
            free: 'GRATIS', // ok
            offers: 'ofertas', // ok agregado
            offer: 'Oferta', // ok agregado
            coupon: 'Cupon', // ok agregado
            save: 'guardar', // ok agregado
            delete: 'eliminar', // ok agregado
            saved: 'guardado', // ok agregado
            saved_a: 'guardada', // ok agregado
            deleted: 'eliminado', // ok agregado
            deleted_a: 'eliminada', // ok agregado
            something_happens: 'ocurre algo con', // ok agregado
            error_on: 'Error al', // ok agregado
            this_offer: 'esta oferta', // ok agregado
            this_coupon: 'este cupón', // ok agregado
            error_on_save: 'Error al guardar, verifica el estado de tu sesión', // ok
        },

        bannerGeneric: {
            signup_google: 'Iniciar con Google', // ok
            signup_facebook: 'Iniciar con Facebook', // ok
            follow: 'Seguir', // ok
            earn_prizes: '¡Gana premios y regalos participando!', // ok
            register_earn_coins: 'Regístrate gratis hoy y gana monedas', // ok
            register_earn_md: 'Regístrate gratis hoy y gana con Jdescuentos', // ok
            follow_us: 'Síguenos para enterarte de ofertas, promociones y descuentos', // ok
            main_image_alt: 'icono de Jdescuentos', // ok agregado
            coin_image_alt: 'icono de moneda', // ok agregado
        },

        btnDiscount: {
            copied: 'Copiado', // ok
            see_coupon: 'Ver cupón', // ok
            participate_lottery: 'Participar en el sorteo', // ok
            see_offer: 'Ver oferta', // ok
            no_coupon_required: 'NO REQUIERE CUPÓN', // ok
        },

        btnSeeMoreResults: {
            see_more: 'Ver más', // ok
        },

        cardDashboardActivity: {
            time_ago: 'Hace', // ok
            see_lottery: 'Ver sorteo', // ok
            see_forum: 'Ver chat/foro', // ok
            see_offer: 'Ver oferta', // ok
            see_coupon: 'Ver cupón', // ok
            unsupported: 'No soportado', // ok
        },

        cardDashboardCoin: {
            coins: 'Monedas', // ok
            see_coins: 'Ver monedas', // // ok
            swap: 'Canjear', // // ok
        },

        cardDashboardLottery: {
            end_at: 'Finaliza en', // ok
            how_participate: '¿Cómo se participa?', // ok
        },

        cardDashboardRouter: {
            end_at: 'Finaliza en {{data|time}}', // no existe
            back: 'Atrás', // ok
        },

        cardMainCoupons: {
            coupon: 'CUPÓN', // ok
            verified: 'Verificado', // ok
            exclusive: 'Exclusivo', // ok
            today: 'HOY', // ok
            tomorrow: 'MAÑANA', // ok
            updated_at: 'Actualizado hace', // ok
            free: 'GRATIS', // ok
            free_ship: 'ENVÍO GRATIS', // ok
            time_ago: 'Hace', // ok
            end_at: 'Finaliza', // ok
            local: 'Local', // ok
            hide: 'Ocultar', // ok
            show: 'Mostrar', // ok
        },

        layoutFooter: {
            follow_in_telegram: 'Únete gratis a nuestro canal de Telegram',
            register_free: 'Regístrate gratis y recibe descuentos en tu email', // ok
            write_email: 'Escribe tu email aquí...', // ok
            subscribe: 'Suscribirse', // ok
            community: 'La comunidad', // ok
            first_of: '#1 de descuentos en Chile.', // ok
            enjoy: 'Disfruta de cupones, ofertas y promociones 100% gratis. Regístrate sin costo y gana monedas por tu actividad. La mejor plataforma para ahorrar se llama Jdescuentos.', // ok
            privacy_policy: 'Política de privacidad', // ok
            terms_of_use: 'Términos de uso', // ok
            cookies_policy: 'Política de cookies', // ok
        },

        cardMainGlobal: {
            verified: 'Verificado', // ok
            exclusive: 'Exclusivo', // ok
            programmed: 'Programado', // ok
            today: 'HOY', // ok
            tomorrow: 'MAÑANA', // ok
            updated_at: 'Actualizado hace', // ok
            time_ago: 'Hace', // ok
            end_at: 'Finaliza', // ok
            local: 'Local', // ok
            new: 'Nuevo', // ok
            see_more: 'Ver más', // ok
            start_at: 'Inicia', // ok
            agreement: 'De acuerdo', // ok
            free: 'GRATIS', // ok agregado
            free_ship: 'ENVÍO GRATIS', // ok agregado
            offers: 'ofertas', // ok agregado
            coupons: 'cupones', // ok agregado
            offer: 'Oferta', // ok agregado
            coupon: 'Cupon', // ok agregado
            save: 'guardar', // ok agregado
            delete: 'eliminar', // ok agregado
            saved: 'guardado', // ok agregado
            saved_a: 'guardada', // ok agregado
            deleted: 'eliminado', // ok agregado
            deleted_a: 'eliminada', // ok agregado
            something_happens: 'ocurre algo con', // ok agregado
            error_on: 'Error al', // ok agregado
            this_offer: 'esta oferta', // ok agregado
            this_coupon: 'este cupón', // ok agregado
            error_on_save: 'Error al guardar, verifica el estado de tu sesión', // ok
        },

        cardMainGlobalSkeleton: {
            new: 'Nuevo', // ok
            see_more: 'Ver más', // ok cambiado
            offers: 'ofertas', // ok agregado
        },

        cardMainLottery: {
            how_participate: 'Como se participa:', // ok
        },

        cardMainMini: {
            free: 'GRATIS', // ok
            free_ship: 'ENVÍO GRATIS', // ok
        },

        cardSharePreviewChat: {
            title: 'Este es el título del chat', // ok
            select: 'Selecciona...', // ok
            select_category: 'Selecciona una categoría', // ok
            description: 'Aquí va la descripción', // ok
        },

        cardSharePreviewLottery: {
            title: 'Aquí va el título de tu chat', // ok
            select: 'Selecciona...', // ok
            select_category: 'Categoría', // ok
            description: 'Aquí va la descripción de tu sorteo y se irá completando poco a poco.', // ok
            your_name: 'Tu nombre', // ok
            lottery_image_seo: 'Imagen del sorteo', // ok agregado
            user_image_seo: 'Imagen de usuario', // ok agregado
        },

        cardSharePreviewOfferAndCoupon: {
            title: 'Aquí va el título de tu', // ok
            new: 'Nuevo', // ok
            description1: 'Aquí va la descripción de tu', // ok agregado
            description2: 'y se irá completando poco a poco.', // ok agregado
            information:
                'Información del sorteo como fechas, nº de veces que se puede participar y todos los detalles…', // ok
            start_at: 'Inicia', // ok
            end_at: 'Finaliza', // ok
            free: 'GRATIS', // ok
            free_ship: 'Envío gratis', // ok
        },

        layoutHeaderSearch: {
            search: 'Buscar', // ok
            must_min_2_chars: 'Debe introducir minimo 2 caracteres para una busqueda mas exacta.', // ok
            okay: 'De acuerdo', // ok
            error: 'Error de búsqueda', // ok
        },

        microStepper: {
            share: 'Compartir', // ok
            description: 'Descripción', // ok
            finished: '¡Finalizado!', // ok
        },

        cardShareStepOne: {
            share: 'Compartir', // ok
            paste_link: 'Pega el link donde se puede usar el código o ver más información.', // ok
            coupon_code: 'Código de cupón', // ok
            no_link_needed: 'No se necesita link', // ok
            start: '¡Empecemos!', // ok
            share_offer: 'Compartir oferta', // ok
            share_coupon: 'Compartir cupón', // ok
            insert_valid_url:
                'Por favor ingresa una url válida o haz click en el botón "No se necesita link"', // ok
            hide: 'Ocultar', // ok
            image_not_found: 'No encontramos ninguna imagen en el enlace, pero puedes agregarlas manualmente', // ok
            error: 'Sucedió un error inesperado, intente nuevamente en unos minutos', // ok
        },

        cardShareStepTwo: {
            share: 'Compartir', // ok
            share_coupon: '¿compartir un cupón?', // ok
            share_offer: '¿compartir una oferta?', // ok
            link: 'Enlace', // ok
            required: 'Obligatorio', // ok
            optional: 'Opcional', // ok
            error: 'Hay un error. Por favor verifica este campo.', // ok
            images_from_link: 'Imágenes del link', // ok
            upload_images_here: 'Subir imagen aquí', // ok
            offer_details: 'Detalles de la oferta (Opcional)', // ok
            error_field: 'Hay un error. Por favor verifica este campo.', // ok
            discount_type: 'Tipo de descuento', // ok
            coupon_code: 'Código del cupón', // ok
            coupon_to_use: 'Cupón a utilizar (Solo si es necesario)', // ok
            title: 'Título', // ok
            set_offer_details: 'Introduce detalles de la oferta (tienda, descuento y lo que quieras)', // ok
            max_140_chars: 'Máximo 140 carácteres', // ok
            description: 'Descripción', // ok
            category: 'Categoría', // ok
            select_category: 'Selecciona la categoría que más se ajusta a tu oferta.', // ok
            where_participate: 'Donde se participa', // ok
            error_fields: 'Hay un error. Por favor verifica los campos obligatorios.', // ok
            start_date: ' Fecha de inicio', // ok
            end_date: 'Fecha de expiración', // ok
            where_send: '¿Desde dónde se envía?', // ok
            local_coupon: '¿Es un cupón local?', // ok
            local_offer: '¿Es una oferta local?', // ok
            send: 'Enviar', // ok
            preview: 'Vista previa',
            select: 'Selecciona...', // ok
            website: 'Sitio Web', // ok
            other: 'Otro', // ok
            description_long:
                'Aquí va tu descripción, añade toda la información posible como condiciones, ubicaciones, etc…', // ok
            insert_valid_url: 'Por favor ingresa una url válida', // ok
            hide: 'Ocultar', // ok
            images_not_found:
                'No encontramos ninguna imagen en el enlace, pero puedes agregarlas manualmente', // ok
            title_40_chars: 'El título debe ser mayor a 40 caracteres', // ok
            default_image_no_removable:
                'No puedes eliminar la imagen por defecto, pero puedes subir o seleccionar otra', // ok
            file_not_permitted: 'Archivo no permitido', // ok
            size_exceeded: 'Archivo excedido de tamaño', // ok
        },

        CardSliderEventFeaturedDiscounts: {
            discount_not_found: 'No encontramos ningún descuento destacado para este evento.', // ok
            featured_discounts: 'Descuentos destacados',
        },

        CardSliderEventFeaturedStores: {
            store_not_found: 'No encontramos ninguna tienda destacada para este evento.', // ok
            featured_stores: 'Tiendas destacadas', // ok
            today: 'Hoy', // ok
            day: 'Día', // ok
            week: 'Semana', // ok
            month: 'Mes', // ok
            always: 'Siempre', // ok
        },

        cardSliderFeaturedDiscounts: {
            featured_offers: 'Ofertas destacadas', // ok
            today: 'Hoy', // ok
            day: 'Día', // ok
            week: 'Semana', // ok
            month: 'Mes', // ok
            always: 'Siempre', // ok
        },

        cardBlog: {
            minutes: 'Minutos', // ok
            like: 'Me gusta', // ok
        },

        cardComment: {
            like: 'Me gusta', // ok
            answer: 'Responder', // ok
            responses: 'respuestas', // ok
            copy_link_to_comment: 'Copiar enlace al comentario', // ok
            report: 'Denunciar', // ok
            why_report: '¿Por qué lo denuncias?', // ok
            spam_offensive: 'Spam/Ofensivo', // ok
            abuse_hate: 'Acoso o incitación al odio', // ok
            okay: 'De acuerdo', // ok
            report_success: 'Su reporte fue enviado exitosamente', // ok
            report_failed: 'No se pudo realizar el reporte', // ok
            answer_published: 'Respuesta publicado de manera exitosa', // ok
            answer_pending: 'Respuesta pendiente de aprobación', // ok
            close: 'Cerrar', // ok
            hide: 'Ocultar', // ok
        },

        cardCommentThread: {
            load_more_answers: 'Cargar respuestas anteriores', // ok
        },

        cardEvent: {
            starts: 'Inicia:', // ok
            ends: 'Finaliza:', // ok
            ended_at: 'Finalizó el', // ok
        },

        cardExtension: {
            now_easier: '¡Ahora lo tienes más fácil!', // ok
            install_our_extension: 'Instala nuestra extensión', // ok
            go_to_extension: 'Ir a la extensión', // ok
        },

        cardFaq: {
            frequent_questions: 'Preguntas frecuentes', // ok
            question: 'Question',
        },

        cardFeatured: {
            coupon: 'CUPÓN', // ok
            offer: 'OFERTA', // ok
            free: 'GRATIS', // ok
            free_ship: 'ENVÍO GRATIS', // ok
        },

        cardFeaturedShop: {
            coupon: 'CUPÓN', // ok
            offer: 'OFERTA', // ok
            free: 'GRATIS', // ok
            free_ship: 'ENVÍO GRATIS', // ok
        },

        cardNews: {
            created_at: 'Se creó el', // ok
        },

        cardNotifications: {
            // TO CHECK
            see_novelty: 'Ver novedad',
            see_novelties: 'Ver novedades',
            see_comment: 'Ver comentario',
            see_publication: 'Ver publicación',
            see_chat: 'Ver chat',
            see_offer: 'Ver oferta',
            see_coupon: 'Ver cupón',
            see_lottery: 'Ver sorteo',
            see_message: 'Ver mensaje',
            see_coins: 'Ver monedas',
            see_forum: 'Ver foro',
            remove: 'Borrar', // ok
            delete_notification: 'Eliminar esta notificación', // ok
            want_delete_notification: '¿Quieres eliminar esta notificación?', // ok
            delete: 'Eliminar', // ok
            deleted_notification: 'notificación eliminada', // ok
            error_delete_notification: 'Ocurrió un error al eliminar la notificación', // ok
            hide: 'Ocultar', // ok
            cancel: 'Cancelar', // ok
            deactivate: 'Desactivar', // ok
            confirm: 'Confirmar', // ok agregada
            stop_receiving_notifications_like_this: 'Deja de recibir notificaciones como esta', // ok
            want_stop_receiving_notifications_like_this:
                '¿Quieres dejar de recibir notificaciones como esta?', // ok
            silenced_notification: 'Notificación silenciada', // ok
            error_silence_notification: 'Ocurrió un error al silenciar la Notificación', // ok
        },

        cardPostUserInfo: {
            created_at: 'Se creó el', // ok
            reading_time: 'Tiempo de lectura:', // ok
            minutes: 'minutos', // ok agregado
            last_update: 'Última actualización', // ok cambiado
            at: 'a las', // ok cambiado
        },

        chipGeneric: {
            posts: 'posts', // ok
        },

        dropdownConfirmAction: {
            yes: 'Si', // ok
            no: 'No', // ok
            hide: 'Ocultar', // ok
            expired_success: 'Expirado satisfactoriamente', // ok
        },

        formFilterByLetter: {
            see_more: 'Ver más', // ok
        },

        formFieldAvatarSelect: {
            having_profile_identifies: 'Tener foto de perfil ayuda a ser identificado.', // ok
            photo: 'foto', // ok
        },

        formFieldGiphy: {
            search_gif: 'Buscar gif', // ok
        },

        formFieldPrice: {
            offer_price: 'Precio en oferta', // ok
            regular_price: 'Precio regular', // ok
            ship_price: 'Costo de envío', // ok
            optional: 'Opcional', // ok
            free_promotion: 'Marca si la promoción es gratis', // ok
            free_ship: 'Envío gratis', // ok
            price_offer_higher_than_regular: 'El precio en oferta no debe ser mayor al precio regular', // ok
        },

        formFieldTabs: {
            percent: 'Porcentaje', // ok
            pesos: 'Pesos', // ok
            free_ship: 'Envío Gratis', // ok
            discount: 'de descuento', // ok
            required: 'Obligatorio', // ok
            required_discount: 'El tipo de descuento es obligatorio', // ok
        },

        formEmailLoginStrategy: {
            email_sended:
                'Hemos enviado un email de 6 dígitos a tu correo electrónico para recuperar tu contraseña', // ok
            check_email:
                'Revisa tu bandeja de entrada y a continuación, ingresa en las casillas el código enviado', // ok
            hide: 'Ocultar', // ok
            welcome: 'Bienvenido a Jdescuentos', // ok
        },

        formForgotPassword: {
            noproblem_go_back: 'No hay problema, vuelve atrás', // ok
            goback: 'Regresar', // ok
            have_troubles: '¿Tienes problemas?', // ok
            contact_us: 'contactanos', // ok
            change_password: 'Cambiar contraseña', // ok
            check_email:
                'Introduce tu correo electrónico y te enviaremos un código de 6 dígitos para restablecer tu contraseña', // ok
            set_email: 'Introduce tu correo', // ok
            email: 'Correo electrónico', // ok
            login_without_password: 'Iniciar sesión sin contraseña', // ok
            restore_password: 'Restablecer contraseña', // ok
        },

        formLogin: {
            email_or_username: 'Correo electrónico o username', // ok
            password: 'Contraseña', // ok
            login: 'Iniciar sesión', // ok
            forgot_password: '¿Olvidaste tu contraseña?', // ok
            remember_login: 'Recordar inicio de sesión', // ok
            login_google: 'Iniciar con Google', // ok
            login_facebook: 'Iniciar con Facebook', // ok
            hide: 'Ocultar', // ok
            welcome: 'Bienvenido a Jdescuentos', // ok
        },

        formMakeComment: {
            answer: 'Responder', // ok
        },
        formQuill: {
            // raro
            add_comment: 'Añadir un comentario...',
            accept_terms: 'Para poder editar debe aceptar los términos y condiciones del sitio',
            verify_account: 'Para realizar un comentario necesitas verificar tu cuenta',
        },

        formRegister: {
            username: 'Username', // ok
            email: 'Email', // ok
            password: 'Contraseña', // ok
            register: 'Registrarse', // ok
            continue_accept_terms: 'Al continuar, acepto las', // ok
            community_rules: 'Términos de uso', // ok
            continue_accept_terms2: 'y la', // ok
            privacy_policy: 'Políticas de privacidad', // ok
            continue_accept_terms3: 'de Jdescuentos.', // ok
            newsletter: 'Recibir el newsletter con ofertas diarias', // ok
            signup_google: 'Regístrate con Google', // ok
            signup_facebook: 'Regístrate con Facebook', // ok
            need_accept_terms: 'Debe estar de acuerdo con los términos y condiciones', // ok
        },

        layoutHeaderLinksWarning: {
            monetized:
                'Algunos enlaces de nuestra web están monetizados. Esto ayuda a que podamos mantener la plataforma.', // ok
        },

        layoutHeaderMenu: {
            menu: 'Menú', // ok
            home: 'Inicio', // ok
            coupons: 'Cupones', // ok
            offers: 'Ofertas', // ok
            free: 'Gratis', // ok
            lotteries: 'Sorteos', // ok
            categories: 'Categorías', // ok
            brands: 'Marcas', // ok
            chats: 'Chats', // ok
            novelties: 'Novedades', // ok
            blog: 'Blog', // ok
        },

        // layoutHeaderMenu: {
        //     discard: 'Descartar'
        // },

        // layoutHeaderMenu: {
        //   search:  'Buscar',
        // close: 'Cerrar',
        //  min_2_chars: 'Debe introducir mínimo 2 caracteres para una búsqueda más exacta.',
        //  okay: 'De acuerdo'
        // },

        layoutHeaderSuggestions: {
            stores: 'Tiendas',
            events: 'Eventos',
            discount: 'descuento',
            discounts: 'descuentos',
            categories: 'Categorías',
            no_results: 'No hay resultados',
        },

        layoutHeaderUser: {
            join_free: 'Iniciar/Registro', // ok
            notifications_panel: 'Panel de notificaciones', // ok
            messages_panel: 'Panel de mensajería', // ok
            share: 'Compartir', // ok
            dashboard: 'Dashboard', // ok
            notifications: 'Notificaciones', // ok
            messages: 'Mensajería', // ok
            saved: 'Guardados', // ok
            coins: 'Monedas', // ok
            rewards: 'Premios', // ok
            settings: 'Ajustes', // ok
            close_session: 'Cerrar sesión', // ok
            in_close_session: 'Estás en inicio de sesión', // ok
            session_closed: 'Sesión cerrada de manera forzada', // ok
            share_discounts: 'Compartir oferta/cupón/sorteo/foro', // ok
        },

        layoutInfoPages: {
            index: 'Índice', // ok
        },

        listThreads: {
            index: 'Índice', // ok
            author: 'Autor', // ok
            title: 'Título', // ok
            thread: 'Tema', // ok
            date: 'Fecha', // ok
            all: 'Todo', // ok
            results_not_found: 'No se encontraron resultados', // ok
        },

        microDiscountFooter: {
            related_categories: 'Categorías relacionadas', // ok
            see_more_categories: 'Ver más categorías', // ok
            related_stores: 'Tiendas relacionadas', // ok
            popular_stores: 'Tiendas populares', // ok
            see_more_stores: 'Ver más tiendas', // ok
        },

        microHeatCounterVote: {
            expired: 'EXPIRADO', // ok
        },

        microTabs: {
            order_by: 'Ordenar por', // ok
        },

        modalAuthAcceptTerms: {
            order_by: 'Ordenar por  {{filter}}', // ok
            accept_terms_message:
                'Para terminar tu registro, solo debes aceptar las Reglas de la comunidad y la Política de Privacidad de Jdescuentos.', // ok,
            quest_accept_terms_message:
                '¿Estás de acuerdo con las Reglas de la comunidad y la Política de Privacidad de Jdescuentos?', // ok,
            continue_accept_terms: 'Al continuar, acepto las', // ok
            community_rules: 'Reglas de la comunidad', // ok
            continue_accept_terms2: 'y la', // ok
            privacy_policy: 'Políticas de privacidad', // ok
            continue_accept_terms3: 'de Jdescuentos.', // ok
            continue: 'Continuar', // ok
            hide: 'Ocultar', // ok
            can_interact: 'Ya puedes interactuar en nuestra comunidad', // ok
            login_to_verify: 'Inicia sesión para poder verificar tu cuenta', // ok
            not_valid: 'Puede que tu cuenta no sea válida, contacta el soporte', // ok
            need_accept_terms: 'Necesita aceptar los términos para continuar',
        },

        modalAuthEmailVerification: {
            verify_email: 'Verificar email', // ok
            registered_success:
                'Te has registrado en la comunidad de Jdescuentos de manera exitosa, te hemos enviado un email de verificación', // ok
            check_spam:
                'Revisa la carpeta de spam para verificar tu cuenta y selecciona que es remitente seguro', // ok
            email_sended: 'Email enviado', // ok
            email_resend: 'Reenviar email', // ok
            email_verify:
                'Te hemos enviado un email de verificación a la dirección de correo electrónico que has registrado', // ok
            email_verify_error:
                'Sucedió un error al enviar el email de verificación, por favor intenta de nuevo en unos minutos', // ok
            continue: 'Continuar', // ok
        },

        modalAuthLogin: {
            login_or_register: 'Inicia sesión o regístrate', // ok
            join_adventages: 'Ventajas de unirte', // ok
            create_account: 'Crear una cuenta', // ok
            login_with_social: 'Inicia sesión con red social', // ok
            login_with_email: 'Inicia con tu correo', // ok
            email_or_username: 'Correo electrónico o username', // ok
            password: 'Contraseña', // ok
            forgot_password: '¿Olvidaste tu contraseña?', // ok
            remember_login: 'Recordar inicio de sesión', // ok
            login: 'Iniciar sesión', // ok
            comment_vote_offers: 'Comenta, vota y envía ofertas', // ok
            participate: 'Participa y gana tarjetas regalo de Amazon y más', // ok
            joinus: 'Únete a la mejor comunidad de ofertas de Chile', // ok
            login_google: 'Iniciar con Google', // ok
            login_facebook: 'Iniciar con Facebook', // ok
            hide: 'Ocultar', // ok
            welcome: 'Bienvenido a Jdescuentos', // ok
            invalid_credentials: 'Credenciales inválidas', // ok
        },

        modalAuthRegister: {
            register: 'Registro', // ok
            welcome: 'Bienvenido a Jdescuentos, regístrate con Facebook, Google o con tu correo', // ok
            have_account: '¿Ya tienes cuenta?', // ok
            register_email: 'Regístrate con tu correo', // ok
            email: 'Correo electrónico', // ok
            username: 'Nombre de usuario', // ok
            password: 'Contraseña', // ok
            continue_accept_terms: 'Al continuar, acepto las', // ok
            community_rules: 'Reglas de la comunidad', // ok
            continue_accept_terms2: 'y la', // ok
            privacy_policy: 'Políticas de privacidad', // ok
            continue_accept_terms3: 'de Jdescuentos.', // ok
            must_accept_terms: 'Debe estar de acuerdo con los términos y condiciones', // ok
            newsletter: 'Recibir el newsletter con ofertas diarias', // ok
            register_account: 'Registrar cuenta', // ok
            register_google: 'Regístrate con Google', // ok
            register_facebook: 'Regístrate con Facebook', // ok
            email_confirmation: 'Confirma tu email. Revisa la carpeta Spam', // ok
        },

        modalAuthResetPasswordInit: {
            reset_password: 'Resetear contraseña', // ok
            ready: 'Todo listo', // ok
            check_email: 'Revisa tu correo y sigue las instrucciones', // ok
        },

        modalCookiesAdjust: {
            back: 'Atrás', // ok
            cookies_adjust: 'Ajustes de cookies', // ok
            save_settings: 'Guardar ajustes', // ok
            read_politics: 'Lee más sobre nuestra Política de Privacidad y Política sobre Cookies', // ok
            cookies_strict: 'Cookies estrictamente necesarias', // ok
            cookies_personalization: 'Cookies de personalización', // ok
            cookies_functional: 'Cookies funcionales', // ok
            cookies_personalization2: 'Cookies de personalización', // ok
            privacy_policy: 'Política de Privacidad', // ok agregada
            cookies_policy: 'Política sobre Cookies', // ok agregada
            and: 'y', // ok agregada
            hide: 'Ocultar', // ok
            cookies_saved: 'Configuración de cookies realizada satisfactoriamente', // ok
        },

        modalCookiesCookies: {
            cookies_image: 'Imagen de cookies', // ok
            cookies_msg: '¡Ey! ¡Somos las cookies!', // ok
            cookies_description1:
                'Usamos cookies y tecnologías similares para hacer funcionar nuestra web y analizar su tráfico. También las usamos, con tu consentimiento, para mostrarte y medir la actuación de nuestros anuncios y ayudarnos a entender cómo interactúas con el contenido y las funcionalidades de la web. Esto hace que podamos seguir mejorando y personalizando tu experiencia usando nuestra plataforma. Puedes estar tranquilo de que tus datos están seguros con nosotros ya que no los vendemos a terceras partes. Al aceptar este mensaje, estás de acuerdo con nuestra', // ok
            cookies_description2: 'y con nuestra', // ok
            cookies_description3: '(de la que puedes actualizar tus preferencias más adelante).', // ok
            privacy_policy: 'Política de Privacidad', // ok agregada
            cookies_policy: 'Política sobre Cookies', // ok agregada
            accept_all: 'Aceptar todo', // ok
            cookies_adjust: 'Ajustes de cookies', // ok
            continue: 'Continuar sin aceptar', // ok
            hide: 'Ocultar', // ok
            cookies_saved: 'Configuración de cookies realizada satisfactoriamente', // ok
        },

        modalDashboardEmail: {
            change_email: 'Cambia tu correo electrónico', // ok
            write_new_email: 'Introduce un nuevo correo electrónico para tu cuenta', // ok
            email_15_days: 'El correo electrónico solo se puede actualizar cada 15 días', // ok
            write_email: 'Escribe tu correo electrónico', // ok
            cancel: 'Cancelar', // ok
            accept: 'Aceptar', // ok
            profile_updated: 'Su perfil se ha actualizado exitosamente', // ok
        },

        modalDashboardPassword: {
            update_password: 'Actualiza tu contraseña', // ok
            insert_new_password: 'Introduce una nueva contraseña', // ok
            current_password: 'Contraseña actual', // ok
            write_current_password: 'Escribe tu contraseña actual', // ok
            new_password: 'Nueva contraseña', // ok
            write_new_password: 'Escribe tu nueva contraseña', // ok
            repeat_new_password: 'Repite la nueva contraseña', // ok
            cancel: 'Cancelar', // ok
            accept: 'Aceptar', // ok
            profile_updated: 'Su perfil se ha actualizado exitosamente', // ok
            password_updated: 'Su contraseña se ha actualizado exitosamente', // ok
        },

        modalDashboardUsername: {
            change_username: 'Cambia tu username', // ok
            insert_new_username: 'Introduce un nuevo username para tu cuenta', // ok
            username_only_one_per_year: 'El nombre de usuario solo se puede actualizar 1 vez por año', // ok
            write_new_username: 'Escribe tu nombre de usuario', // ok
            cancel: 'Cancelar', // ok
            accept: 'Aceptar', // ok
            no_blank_spaces: 'No puede contener espacios en blanco', // ok
            profile_updated: 'Su perfil se ha actualizado exitosamente', // ok
        },

        modalMessagesNewMessage: {
            new_message: 'Nuevo mensaje', // ok
            to: 'Para', // ok
            username: 'Nombre de usuario', // ok
            no_users_available: 'No hay usuarios disponibles', // ok
            message: 'Escribe tu mensaje', // ok
            cancel: 'Cancelar', // ok
            send: 'Enviar', // ok
        },

        modalCoupon: {
            copy_and_paste1: 'Copia el código y pégalo en', // ok cambiado
            copy_and_paste2: 'para obtener tu descuento', // ok cambiado
            copied: 'Copiado', // ok
            copy_and_go: 'Copiar cupón e ir a la tienda', // ok
            share: 'Compartir', // ok
            thanks_report: 'Gracias por su reporte', // ok
            coupon_required: 'NO REQUIERE CUPÓN', // ok
        },

        modalGeneric: {
            thanks_one: 'Gracias por tu comentario ¡ha sido enviado exitosamente! Mientras', // ok
            thanks_two: '¡Explora entre las mejores ofertas y cupones!', // ok
        },

        modalImageZoom: {
            image: 'Imagen', // ok
        },

        sidebarCategories: {
            categories: 'Categorías', // ok
            see_more: 'Ver más', // ok
            no_categories: 'No hay categorías disponibles', // ok
        },

        sidebarFeaturedOffers: {
            featured_offers: 'Ofertas destacadas', // ok
            see_more: 'Ver más', // ok
            get: 'Obtener', // ok
        },

        sidebarFeaturedCoupons: {
            featured_coupons: 'Cupones destacados', // ok
            see_more: 'Ver más', // ok
            get: 'Obtener', // ok
        },

        cardMainSidebarDiscount: {
            see_coupon: 'Ver cupón', // ok
            free: 'GRATIS', // ok
            free_ship: 'ENVÍO GRATIS', // ok
        },

        sidebarGiveMe: {
            get: 'Consígueme también en', // ok
        },

        sidebarMobileCategory: {
            categories: 'Categorías', // ok
        },

        sidebarNetworkPages: {
            socials: 'Redes de', // ok
        },

        sidebarOfferSummary: {
            coupons_for_shop: 'Número de cupones para tienda:', // ok
            offers_for_shop: 'Número de ofertas para tienda:', // ok
            last_update: 'Ultima actualización:', // ok
            last_week: 'Nuevos descuentos última semana:', // ok
            all_discounts: 'Número de descuentos totales:', // ok
        },

        sidebarPaymentMethods: {
            methods: 'Métodos de pago', // ok
            accepted_methods: 'Métodos de pago que acepta', // ok
        },

        sidebarShare: {
            share: 'Compartir', // ok
            copy_link: 'Copiar link', // ok
            copied: '¡Copiado!', // ok
            email: 'Correo electrónico', // ok
        },

        sidebarSimilar: {
            see_more: 'Ver todo', // ok
        },

        cardSidebarSomeCategories: {
            similar_categories: 'Categorias similares', // ok
            no_categories: 'No hay categorías similares disponibles', // ok
        },

        sidebarStores: {
            see_more: 'Ver más', // ok
            popular_stores: 'No hay tiendas populares disponibles', // ok
        },

        layoutError: {
            no_exist: 'Esta página no existe', // ok
            // ok
            description:
                'Lo sentimos, esta página no existe o no está disponible, pero tranquilo, puede ser un error nuestro también. Haciendo clic en los botones puedes volver donde estabas o volver a la página de inicio para empezar de 0. Si ves esta página muchas veces, por favor, contáctanos', // ok
            go_back: 'Volver donde estaba', // ok
            go_home: 'Ir al inicio', // ok
            try_again:
                'Intenta iniciar sesión con el usuario correcto, actualiza la página y si sigues teniendo problemas', // ok cambiado
            send_message: 'envíanos un mensaje.', // ok agregado
            title_seo: 'Error | Jdescuentos', // ok agregado
            description_seo: 'Pagina de error', // ok agregado
        },
    },
    lib: {
        variant_dict: {
            offer: {
                plural: 'ofertas', // ok
                singular: 'oferta', // ok
                singular_one: 'una oferta', // ok
                plain: 'oferta', // ok
            },
            coupon: {
                plural: 'cupones', // ok
                singular: 'cupón', // ok
                singular_one: 'un cupón', // ok
                plain: 'cupon', // ok
            },
            lottery: {
                plural: 'sorteos', // ok
                singular: 'sorteo', // ok
                singular_one: 'un sorteo', // ok
            },
            chat: {
                plural: 'chats', // ok
                singular: 'chat', // ok
                singular_one: 'un chat', // ok
            },
        },

        date_functions: {
            months: [
                'enero',
                'febrero',
                'marzo',
                'abril',
                'mayo',
                'junio',
                'julio',
                'agosto',
                'septiembre',
                'octubre',
                'noviembre',
                'diciembre',
            ], // ok
            times: {
                sec: 'seg',
                min: 'min',
                hs: 'hs',
                day: 'día/s',
                week: 'sem',
                month: 'mes/es',
                year: 'año/s',
            }, // ok
            ends_today: 'Finaliza HOY', // no existe
            ends_tomorrow: 'Finaliza MAÑANA', // no existe
            ends_in: 'Finaliza en {{days}} días', // no existe
            ends_in_week: 'Finaliza en 1 semana', // no existe
            ends_at: 'Finaliza el {{date}}', // no existe
            datetimeFormat: 'es', // ok agregado
            timezone: 'America/Santiago', // ok agregado
        },
    },
    middleware: {
        ifAcceptTerms: {
            text: 'Para compartir oferta, cupon, sorteo o chat/foro debes aceptar los términos y condiciones del sitio', // ok
        },
        ifAuthenticated: {
            text: 'No tienes permisos para acceder a esta página', // ok
        },
    },
    plugins: {
        axiosInterceptors: {
            for: 'Para',
            need_accept_terms: 'necesitas aceptar los terminos y condiciones',
            need_verify_account: 'necesitas verificar tu cuenta.',
            do_votes: 'realizar votaciones',
            do_save_discounts: 'guardar descuentos',
            do_mark_expired: 'marcar como expirado',
            do_report: 'reportar',
            do_likes: 'dar likes',
            do_comment: 'realizar un comentario',
            do_create_forum: 'crear un foro/chat',
            do_create_lottery: 'crear un sorteo',
            do_create_coupon: 'crear un cupon',
            do_create_offer: 'crear una oferta',
            no_do_action: 'Tu cuenta no permite realizar esta acción',
            no_permission: 'No tienes permisos para acceder a esta página',
        },
    },
    views: {
        categoriesBridge: {
            not_found: 'Página no encontrada', // ok
            news: 'nuevos', // ok
            commented: 'comentados', // ok
        },

        categoriesList: {
            categories: 'Categorías', // ok
            categories_letter: 'Categorías letra', // ok
            categories_letter_starts1: 'Todas las categorías que comienza con la letra', // ok cambiado
            categories_letter_starts2: 'se muestran en esta página', // ok cambiado
            md: 'Jdescuentos', // ok agregado
            category_md: 'Categorías | Jdescuentos', // ok agregado
        },

        categoryDetails: {
            new_offers_in: 'Nuevas ofertas en', // ok
            best_offers: 'Mejores ofertas aquí', // ok
            description: 'Descripción', // ok
            similar_categories: 'Categorias similares', // ok
            popular_stores: 'Tiendas Populares', // ok
            populars: 'Populares', // ok
            news: 'Nuevos', // ok
            commented: 'Comentados', // ok
            check_offers: 'Mientras, puedes revisar más ofertas', // ok
            best_week_discounts: 'Mejores cupones & ofertas de la semana', // ok
            offers: 'ofertas', // ok
            in: 'en', // ok
            no_offers_found1: 'En este momento no tenemos', // ok cambiado
            no_offers_found2: ', pronto volverán a estar disponibles.', // ok cambiado
        },

        discountDetails: {
            coupon_may_not_work: 'El cupón puede no funcionar, se publicó hace más de 1 mes.', // ok
            offer_may_not_work: 'La oferta puede no funcionar, se publicó hace más de 1 mes.', // ok
            expand: 'Expandir', // ok
            published_about: 'Publicado hace', // ok
            updated_at: 'Actualizado hace', // ok
            see_more: 'Ver más', // ok
            offers: 'Ofertas', // ok
            how_participate: 'Como se participa', // ok
            how_much_times_participate: 'Cuantas veces se puede participar', // ok
            member_since: 'Miembro desde:', // ok
            details: 'Detalles', // ok
            information: 'Información', // ok
            ends: 'Termina', // ok
            exclusive: 'Exclusivo', // ok
            verified: 'Verificado', // ok
            start_at: 'Inicia', // ok
            end_at: 'Finaliza', // ok
            local: 'Local', // ok
            sended_from: 'Enviado desde', // ok
            categories: 'Categorías', // ok
            share: 'Compartir', // ok
            affiliate_comission:
                'Jdescuentos puede recibir una comisión de afiliado cuando haces clic o compras a través de nosotros. Te agradecemos que ahorres con nosotros.', // ok
            related_coupons: 'Cupones relacionados', // ok
            related_offers: 'Ofertas relacionadas', // ok
            no_exist: 'No hay', // ok agregado
            similar_by_now: ' similares por ahora', // ok agregado
            comments: 'Comentarios', // ok
            following: 'Siguiendo', // ok
            follow: 'Seguir', // ok
            read_more: 'Leer más', // ok
            more_relevant: 'Más relevantes', // ok
            more_recent: 'Más recientes', // ok
            no_comments_yet: 'Todavía no hay comentarios', // ok
            be_first_commenting: 'Comenta el primero y obtén me gustas en tu comentario', // ok
            no_offer_found: 'No se encontró esta oferta', // ok
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente mas tarde', // ok
            today: 'HOY', // ok
            tomorrow: 'MAÑANA', // ok
            in_3_days: 'en 3 días', // ok
            see_comments_relevant: 'Ver comentarios más relevantes', // ok
            see_all_comments_more_recent_first:
                'Ver todos los comentarios, los comentarios más recientes se muestran primero', // ok
            save: 'Guardar', // ok
            report: 'Reportar', // ok
            why_report: '¿Por qué lo reportas?', // ok
            spam_ofensive: 'Spam/Ofensivo', // ok
            duplicated: 'Duplicado', // ok
            offer_is_published: 'Ya esta la oferta publicada', // ok
            refered_links: 'Enlaces referidos', // ok
            expired_offer_or_link_not_working: 'Oferta expirado o no funciona el enlace', // ok
            other: 'Otro', // ok
            free_ship: 'Envío gratis', // ok
            free_ship_uppercase: 'ENVÍO GRATIS', // ok
            free: 'GRATIS', // ok
            home: 'Home', // ok
            error_at: 'Error al', // ok agregado
            delete: 'eliminar', // ok agregado
            offer: 'Oferta', // ok agregado
            coupon: 'Cupón', // ok agregado
            coupons: 'cupones', // ok agregado
            save_min: 'guardar', // ok agregado
            something_happens_with: 'ocurre algo con', // ok agregado
            this_offer: 'esta oferta', // ok agregado
            this_coupon: 'este cupón', // ok agregado
            saved_a: 'guardada', // ok agregado
            saved: 'guardado', // ok agregado
            deleted: 'eliminado', // ok agregado
            deleted_a: 'eliminada', // ok agregado
            close: 'Cerrar', // ok
            error_on_delete_offer: 'Error al eliminar, ocurre algo con esta oferta', // ok
            error_on_save_coupon: 'Error al guardar, ocurre algo con este cupón', // ok
            okay: 'De acuerdo', // ok
            report_success: 'Su reporte fue enviado exitosamente', // ok
            report_error: 'No se pudo realizar el reporte', // ok
            comment_published: 'Comentario publicado de manera exitosa', // ok
            comment_pending: 'Comentario pendiente de aprobación', // ok
            hide: 'Ocultar', // ok
            comment_cant_create: 'Comentario no se pudo crear', // ok
        },

        discountMask: {
            press_to_copy: 'Presiona el código para copiarlo', // ok
            code_copied_success: '¡Código copiado correctamente!', // ok
            bring: 'Te llevaremos', // ok
            when_copy_code: 'cuando copies el código', // ok
            moving_in: 'Llevándote', // ok cambiado
            in: 'en', // ok agregado
            remember_apply_code: 'Recuerda aplicar el código al finalizar la compra', // ok
            expired_coupon: 'Parece que este cupón puede haber expirado', // ok
            error_obtaining_coupon: 'Ocurrió un error al obtener los datos del cupón, contacta el soporte', // ok
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente más tarde', // ok
            no_coupon_required: 'NO REQUIERE CUPÓN', // ok
            go_to: 'Ir a', // ok
            go_store: 'Ir a la tienda', // ok
        },

        homepage: {
            best_week_offers: 'Cupones de descuento y ofertas México',
            best_offers_and_coupons_from: 'Mejores ofertas y cupones de',
            popular_stores: 'Tiendas Populares',
            top_categories: 'Top categorias',
            see_more: 'Ver mas',
            popular: 'Populares',
            news: 'Nuevos',
            commented: 'Comentados',
            schemeOrg: {
                types: {
                    '@type': 'WebSite' as 'WebSite',
                    name: 'MEGAdescuentos',
                    url: 'https://www.megadescuentos.com',
                },
                info: {
                    '@type': 'Organization' as 'Organization',
                    name: 'MEGAdescuentos',
                    url: 'https://www.megadescuentos.com',
                    brand: 'MEGAdescuentos',
                    logo: 'https://www.megadescuentos.com/assets/schema.org/organisation/megadescuentos.png',
                    email: 'MEGAdescuentos@hurtadodeals.com',
                    founder: 'Juan Hurtado',
                    address: 'Avenida Nuevo León 161, 2 piso, Hipódromo, Ciudad de México, México, 06100',
                    sameAs: [
                        'https://www.instagram.com/megadescuentosmx/',
                        'https://twitter.com/megadescuentosm',
                        'https://www.youtube.com/channel/UCaL6vzKyHpO7mu6gbu47Z9g',
                        'https://www.tiktok.com/@megadescuentosm',
                        'https://www.facebook.com/megadescuentosm/',
                        'https://www.linkedin.com/company/81934995/admin/',
                    ],
                },
            },
        },

        storeDetails: {
            coupons_of: 'Códigos de descuento :tienda',
            we_dont_have: 'En este momento no tenemos',
            in_the_store: 'en la tienda',
            coupons: 'cupones',
            featured_weekly_offers: 'Mejores descuentos :tienda',
            best_coupons_here: 'Mejores cupones aquí',
            will_be_available: 'pronto volverán a estar disponibles',
            best_coupons: 'Mientras, puedes revisar los mejores cupones',
            offers: 'Ofertas',
            check_offers: 'Mientras, puedes revisar las mejores ofertas',
            best_offers: 'Mejores ofertas aquí',
            recent_coupons: 'Cupones recientes de',
            discount: 'Descuento',
            details: 'Detalles',
            discount_details: 'del descuento',
            expires: 'Expira',
            free_ship: 'ENVÍO GRATIS',
            free: 'GRATIS',
            offers_and_coupons_expired_from: 'Ofertas y Cupones expirados de',
            promotions_from: 'Promociones de',
            discount_resume: 'Resumen de descuentos',
            similar_stores: 'Tiendas Similares',
            popular_stores: 'Tiendas Populares',
            home: 'Home',
            stores: 'Tiendas',
            valid_until_advice: 'Válido hasta nuevo aviso',
            comissions_on: {
                title: 'Esta tienda nos ayuda a mantener MEGAdescuentos',
                header_link_text:
                    'MEGAdescuentos puede ganar una comisión cuando utilices los cupones de esta página.',
                texts: [
                    'Al utilizar un cupón en esta página, podemos obtener una comisión a través de un acuerdo de afiliación con _STORE_. Esto para ti no tiene ningún costo adicional y nos ayuda a mantener MEGAdescuentos en línea con nuestra misión: ayudar a ahorrar en tus compras en línea gracias a nuestros cupones.',
                    'La mayoría de las tiendas y marcas en MEGAdescuentos no tienen acuerdos de afiliación y no recibimos ningún tipo de comisión ni ingresos por parte de las mismas. Nuestra misión es ayudar a los compradores en línea en la mayor cantidad de tiendas posibles.',
                    'La confianza de los compradores es muy importante para nosotros, y queremos ser completamente transparentes sobre cómo generamos ingresos. Nuestras páginas que tienen acuerdos de afiliación están claramente marcadas.',
                    'No mostramos ningún anuncio ni banner en nuestra web (no nos gusta y nos parece molesto), y no vendemos ninguna de nuestra información personal o de compras.',
                ],
                link: {
                    path: '/blog/recomendaciones/como-ganamos-dinero-en-megadescuentos-y-como-mantenemos-el-sitio',
                    text: 'Aprende más sobre cómo MEGAdescuentos genera ingresos',
                },
            },
            comissions_free: {
                title: 'No generamos comisiones ni anuncios con esta tienda en MEGAdescuentos',
                header_link_text:
                    'No ganamos ninguna comisión en esta tienda ni mostramos anuncios. Es 100% libre.',
                texts: [
                    'En MEGAdescuentos, no obtenemos beneficios económicos de esta tienda. No usamos enlaces afiliados, no ganamos comisiones en las compras de _STORE_, no mostramos publicidad (no nos gustan los banners molestos) y no vendemos tu información personal o de compras.',
                    'Nuestra misión es ayudar a tantos compradores a ahorrar dinero al finalizar su compra en línea. Por eso, ofrecemos códigos de descuento de tantas tiendas como sea posible, aunque en algunas no ganemos nada de dinero.',
                    'El uso de esta página es completamente gratuito, aunque mantener MEGAdescuentos tiene un costo. En algunas páginas, ganamos comisiones a través de acuerdos de afiliación al hacer clic o usar un código de descuento. La confianza de los compradores es muy importante para nosotros, y queremos ser totalmente transparentes sobre cómo generamos ingresos. Las páginas con enlaces afiliados están claramente identificadas.',
                ],
                link: {
                    path: '/blog/recomendaciones/como-ganamos-dinero-en-megadescuentos-y-como-mantenemos-el-sitio',
                    text: 'Lee más sobre cómo ganamos dinero',
                },
            },
            how_we_verify_coupons: {
                title: '¿Como verificamos los cupones?',
                texts: [
                    'MEGAdescuentos.com es una comunidad de cupones y descuentos, donde usuarios y editores de contenido buscan las mejores ofertas y códigos de descuento de _STORE_ cada día, para que puedas ahorrar un poco de dinero gracias a nosotros.',
                    ' Nuestra misión es que puedas conseguir un descuento que funciona y ofrecerte toda la información y ayuda para que puedas economizar en tu compra en línea. Para ofrecerte más garantías, hay algunos cupones y promociones marcadas con el check de Verificado, para mayor garantía y seguridad. Nuestro equipo verificó los cupones, ofertas y promociones de _STORE_ por última vez el día _UPDATED_.',
                ],
                link: {
                    path: '/blog/guias-de-ahorro/unete-gana-y-ahorra-te-decimos-por-que-confiar-en-megadescuentos',
                    text: 'Mira Cómo Verificamos los Cupones.',
                },
            },
        },

        storesBridge: {
            category_not_found: 'Esta categoría no fue encontrada', // ok
            store_not_found: 'Esta tienda no fue encontrada', // ok
            error: 'Sucedió un problema inesperado, por favor intente nuevamente más tarde', // ok
        },

        storesList: {
            popular_stores: 'Marcas Populares', // ok
            stores_letter: 'Marcas letra', // ok
            md: '| Jdescuentos', // ok
            stores: 'Marcas', // ok
            stores_with_letter1: 'Todas las marcas que comienzan con la letra', // ok
            stores_with_letter2: 'se muestran en esta página.', // ok
        },
    },
    pages: {
        landing: {
            commission_on: 'puede ganar una comisión cuando ces los cupones de esta página.',
            commission_free:
                'No ganamos ninguna comisión en esta tienda ni mostramos anuncios. Es 100% libre.',
        },
        subscribe: {
            success: 'Satisfactorio', // ok
            ops: 'Oops...', // ok
            congrats: 'Felicidades! Ya estas suscrito a nuestro newsletter!', // ok
            existing: 'Al parecer ya estabas suscrito a nuestro newsletter!', // ok
            error: 'Al parecer ocurrió un error al intentar suscribirte, pero puedes intentarlo nuevamente en unos segundos.', // ok
            title: 'Suscripción | Jdescuentos', // ok
            description: 'Suscripción al newsletter de Jdescuentos', // ok
        },

        unsubscribe: {
            success: 'Satisfactorio', // ok
            ops: 'Oops...', // ok
            congrats: 'Te acabas de desuscribir de nuestro newsletter', // ok
            error: 'Al parecer ocurrió un error al intentar desuscribirte, pero puedes intentarlo nuevamente en unos segundos.', // ok
            title: 'Desuscripción | Jdescuentos', // ok
            description: 'Borrarse del newsletter de Jdescuentos', // ok
        },

        blogArticle: {
            index: 'Índice', // ok
            valorate: 'Valorar', // ok
            similar_articles: 'Artículos similares', // ok
            event_not_found: 'Este evento no fue encontrado', // ok
            error: 'Sucedió un problema inesperado, por favor intente nuevamente mas tarde', // ok
            hide: 'Ocultar', // ok
            error_on_valorate: 'Ocurrió un error al valorar el artículo', // ok
            home: 'Home', // ok
            blog: 'Blog', // ok
        },

        blogCategory: {
            all_articles_about: 'Todos los artículos sobre', // ok
            category_no_contents: 'Esta categoría no tiene contenidos aún', // ok
            category_not_found: 'Esta categoría no fue encontrada', // ok
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente más tarde', // ok
            articles_about: 'Artículos sobre {{category}} | Jdescuentos', // ok
            all_articles: 'Aquí encontrarás todos los artículos sobre {{category}} en Jdescuentos', // ok
            home: 'Home', // ok
            blog: 'Blog', // ok
        },

        blog: {
            advices: 'Consejos', // ok agregado
            and: 'y', // ok agregado
            guides: 'guías', // ok agregado
            to_save_money: 'para ahorrar dinero', // ok agregado
            best_guides: 'Descubre los mejores posts y guías para ahorrar en tus compras por internet.', // ok
            articles_about: 'Artículos sobre', // ok
            see_more: 'Ver más', // ok
        },

        compartirCuponCrear: {
            at_coupon: 'del cupón', // ok
            title_seo: 'Compartir un cupón | Jdescuentos', // ok
            description_seo: 'Página para compartir tus cupones', // ok
            error_on_create: 'Error al crear el cupón, por favor, intentalo nuevamente', // ok
            coupon_created: 'Cupón creado satisfactoriamente', // ok
        },

        compartirCupon: {
            title_seo: 'Compartir un cupón | Jdescuentos', // ok
            description_seo: 'Página para compartir tus cupones', // ok
        },

        compartirOfertaCrear: {
            of_the_offer: 'de la oferta', // ok
            title_seo: 'Compartir una oferta | Jdescuentos', // ok
            description_seo: 'Página para compartir tus ofertas', // ok
            error_on_create: 'Error al crear la oferta, por favor, intentalo nuevamente', // ok
            offer_success: 'Oferta creada satisfactoriamente', // ok
        },

        compartirOferta: {
            title_seo: 'Compartir una oferta | Jdescuentos', // ok
            description_seo: 'Página para compartir tus ofertas', // ok
        },

        compartirChat: {
            chat: 'chat', // ok
            title_seo: 'Compartir chat | Jdescuentos', // ok
            description_seo: 'Página para compartir un chat', // ok
            error_on_create: 'Error al crear el chat, por favor, intentalo nuevamente', // ok
        },

        compartirFinalizado: {
            post_received: 'Hemos recibido tu', // ok
            in_revision: 'y está en revisión.', // ok
            when_published: 'Cuando esté publicada recibirás una notificación.', // ok
            be_patient: 'Ten paciencia, lo revisaremos en breve', // ok
            recommend: 'Mientras que se publica tú {{post}}, te recomendamos:', // ok
            see_offers: 'Ver Ofertas Populares', // ok
            see_coupons: 'Ver Cupones Populares', // ok
            your: 'Tu', // ok
            published: ' se ha publicado con éxito!', // ok
            see_post: 'Aquí puedes ver tu', // ok
            title_seo: 'Finalizado! | Jdescuentos', // ok
            description_seo: 'La publicación se realizó satisfactoriamente', // ok
        },

        compartir: {
            share_question: '¿Qué te gustaría compartir?', // ok
            offer: 'Oferta', // ok
            share_offer: 'Comparte una oferta o promoción que sea atractiva', // ok
            coupon: 'Cupón', // ok
            share_coupon: 'Comparte un cupón y no te olvides de incluir el código de descuento', // ok
            lottery: 'Sorteo', // ok
            share_if_socials: 'Si has visto un sorteo en redes sociales o en algún lugar, compártelo', // ok
            chat: 'Chat y discusiones', // ok
            open_thread: 'Abre un debate o pregunta a la comunidad cualquier cosa', // ok
            title_seo: 'Compartir | Jdescuentos', // ok
            description_seo:
                'Página para compartir tus publicaciones como ofertas, cupones, sorteos y chats...', // ok
        },

        compartirSorteo: {
            of_lottery: 'del sorteo', // ok
            title_seo: 'Compartir sorteo | Jdescuentos', // ok
            description_seo: 'Página para compartir un sorteo.', // ok
            error: 'Error al crear el sorteo, por favor, intentalo nuevamente', // ok
        },

        cupones: {
            featured_coupons: 'Cupones destacados', // ok
            featured_stores: 'Marcas destacadas', // ok
            description: 'Description', // ok
            featured_shop: 'Tiendas Populares', // ok
            populars: 'Populares', // ok
            news: 'Nuevos', // ok
            commented: 'Comentados', // ok
            home: 'Home', // ok
            coupons: 'Cupones', // ok
        },

        dashboardUserActividad: {
            recent_activity: 'Actividad reciente', // ok
            no_activity: 'Sin actividad en los últimos 30 días', // ok
            title_seo: 'Dashboard de {{name}} | Jdescuentos', // ok
            description_seo: 'Actividades recientes que {{name}} ha realizado en tu cuenta', // ok
        },

        dashboardUserChats: {
            chats: 'Chats', // ok
            error: '¡outs! al parecer este usuario aún no ha creado o participado en chats', // ok
            title_seo: 'Dashboard de {{name}} | Chats | Jdescuentos', // ok
            description_seo: 'Chats/foros que {{name}} ha publicado en Jdescuentos', // ok
        },

        dashboardUserDescuentos: {
            offer_coupons_published: 'Ofertas/cupones publicados', // ok
            no_discounts: 'Este usuario aún no tiene ofertas/cupones publicadas', // ok
            title_seo: 'Dashboard de {{name}} | Descuentos | Jdescuentos', // ok
            description_seo: 'Descuentos que {{name}} ha publicado en Jdescuentos', // ok
        },

        dashboardUserSorteos: {
            lotteries: 'Sorteos', // ok
            error: '¡Outs! al parecer este usuario no tiene sorteos', // ok
            title_seo: 'Dashboard de {{name}} | Sorteos | Jdescuentos', // ok
            description_seo: 'Sorteos qué {{name}} ha publicado en Jdescuentos', // ok
        },

        dashboardUserIndex: {
            see: 'Ver', // ok
            recent_activity: 'Actividad reciente', // ok
            discounts_published: 'Ofertas/cupones publicados', // ok
            chats: 'Chats', // ok
            lotteries: 'Sorteos', // ok
            title_seo: 'Dashboard de {{name}} | Jdescuentos', // ok
            description_seo: 'Dashboard de {{name}}', // ok
        },

        dashboardUserMain: {
            member_since: 'Miembro desde:', // ok
            followers: 'Seguidores', // ok
            rank: 'Ranking de usuario', // ok
            follow: 'Seguir', // ok
            rewards: 'Recompensas', // ok
            stats: 'Estadísticas', // ok
            verified_user: 'Usuario verificado', // ok
            user_was_verified: 'Tu usuario ya ha sido verificado', // ok
            // ok
            verified_description:
                'Es un usuario que tiene la insignia de “Verificado” y lo consigue tras 25 ofertas/cupones aprobados y 50 comentarios aprobados. Se le debe enviar un correo electrónico para informarle y además se le informará por la mensajería y en notificaciones.',
            offer_coupon: 'Cupones/ofertas', // ok
            comments: 'Comentarios', // ok
            profile_not_found: 'Perfil de usuario no encontrado', // ok
            coins_available: 'Monedas disponibles', // ok
            coins_total: 'Monedas totales', // ok
            awards: 'Premios', // ok
            lotteries: 'Sorteos', // ok
            chats: 'Chats', // ok
            likes_sended: 'Likes enviados en ofertas y comentarios', // ok
        },

        dashboardActividad: {
            recent_activity: 'Actividad reciente', // ok
            no_activity: 'Sin actividad en los últimos 30 días', // ok
            publish_offer: 'Publicar oferta', // ok
            publish_coupon: 'Publicar cupón', // ok
            title_seo: 'Tu dashboard | Jdescuentos', // ok
            description_seo: 'Actividades recientes que has realizado en tu cuenta', // ok
        },

        dashboardAjustes: {
            account: 'Mi cuenta',
            username: 'Username',
            change: 'Cambiar',
            password: 'Contraseña',
            set_new_password: 'Establece una nueva contraseña',
            new_password: 'Nueva contraseña',
            email: 'Correo electrónico',
            not_verified: 'No verificado (Revisa tu buzón de emails)',
            resend_email: 'Reenviar email de verificación',
            delete_account: 'Eliminar cuenta',
            notifications: 'Notificaciones',
            notifications_web: 'Notificaciones web',
            notifications_email: 'Notificaciones por email',
            new_novelties_post: 'Nuevo post de novedades ha sido publicado.',
            new_private_message: 'Mensaje nuevo en tu bandeja de entrada.',
            coins_received: 'Recibí monedas.',
            comments: 'Comentarios',
            someone_answers_to_comment: 'Alguien responda a un comentario mío.',
            multiple_likes_to_comment: 'Múltiples usuarios dan like a un comentario mío.',
            my_comment_liked: 'Alguien da me gusta a un comentario mío.',
            offers_coupons: 'Ofertas/cupones',
            discount_first_grade: 'Mi oferta/cupón alcanza el primer grado de temperatura.',
            discount_popular_250: 'Mi oferta/cupón sea popular (+250º)',
            discount_popular_500: 'Mi oferta/cupón sea popular (+500º)',
            discount_other_popular_500: 'Una oferta/cupón de otra persona sea muy popular (+500º)',
            discount_not_popular_minus_25: 'Mi oferta/cupón no sea popular (-25°)',
            discount_expired: 'Una oferta/cupón mía haya expirado.',
            report_publication_approved: 'Mi reporte de una publicación es aprobado.',
            report_publication_rejected: 'Mi reporte de una publicación es rechazado.',
            specials: 'Especiales',
            follow_telegram: 'Seguir canal de Telegram',
            telegram_url: 'https://t.me/megadescuentosm',
            go_telegram: 'Ir al canal de Telegram',
            telegram_description:
                'En este canal de Telegram envíamos las mejores ofertas, cupones y sorteos de cada día, un resumen de la mejor selección para que no tengas que entrar a la web a cada rato.', // ok
            email_offers: 'Ofertas por email', // ok
            receive_best_offers_daily: 'Recibir notificaciones de mejores ofertas diarias.', // ok
            receive_best_offers_week: 'Recibir notificaciones de las mejores ofertas de la semana.', // ok
            receive_recomendations: 'Recibir notificaciones de recomendaciones de Jdescuentos.', // ok
            newsletter: 'Newsletter', // ok
            receive_newsletter: 'Recibir notificaciones de newsletter.', // ok
            receive_no_readed_notifications: 'Recibir notificaciones no leídas de forma semanal.', // ok
            general: 'General', // ok
            novelties_published: 'Un nuevo post de novedades ha sido publicado.', // ok
            inbox_received: 'Un mensaje nuevo en tu bandeja de entrada.', // ok
            when_someone_tags_in_comment: 'Cuando alguien me etiqueta en un comentario.', // ok
            when_someone_answer_comment: 'Cuando alguien responda a un comentario mío.', // ok
            when_post_receives_first_comment: 'Cuando una publicación mía recibe el primer comentario.', // ok
            when_post_receives_commments: 'Cuando una publicación mía recibe comentarios.', // ok
            my_comment_approved: 'Un comentario tuyo ha sido aprobado.', // ok
            when_discount_popular_250: 'Cuando mi oferta/cupón sea popular (+250º).', // ok
            when_discount_popular_500: 'Cuando mi oferta/cupón sea muy popular (+500º).', // ok
            when_discount_minus_25: 'Cuando mi oferta/cupón no sea popular (-25°).', // ok
            when_other_discount_popular_500:
                'Cuando una oferta/cupón de otra persona sea muy popular (+500º).', // ok
            when_discount_expired: 'Cuando una oferta/cupón mía haya expirado.', // ok
            when_discount_published: 'Cuando mi oferta/cupón se haya publicado.', // ok
            save_settings: 'Guardar ajustes', // ok
            social_connection: 'Conexión social', // ok
            created_accounts: 'Cuentas conectadas', // ok
            google_connected: 'Conectado con Google', // ok
            facebook_connected: 'Conectado con Facebook', // ok
            preferences_get_error: 'No se pudieron obtener las preferencias del usuario', // ok
            title_seo: 'Ajustes | Jdescuentos', // ok
            description_seo: 'Configuración de tu cuenta', // ok
            google_connected_account: 'Cuenta Google conectada:', // ok
            google_not_connected: 'No tienes tu cuenta de Google conectada.', // ok
            facebook_connected_account: 'Cuenta Facebook conectada:', // ok
            facebook_not_connected: 'No tienes tu cuenta de Facebook conectada.', // ok
            success_account_image: 'Su imagen de perfil se actualizó de manera exitosa', // ok
            error_account_image: 'Error al actualizar su imagen de perfil', // ok
            success_updating_preferences_web:
                'Se actualizaron las preferencias de notificaciones web de manera exitosa', // ok
            error_updating_preferences_web: 'Error al actualizar las preferencias de notificaciones web', // ok
            success_updating_preferences_email:
                'Se actualizaron las preferencias de correo de manera exitosa', // ok
            error_updating_preferences_email: 'Error al actualizar las preferencias de correo', // ok
            email_verification_sended: 'Te hemos enviado un email de verificación', // ok
            are_sure_delete_account: '¿Estás seguro de que quieres eliminar tu cuenta?', // ok
            success_deleting_account: 'Cuenta eliminada de manera exitosa', // ok
            error_deleting_account: 'Error al intentar eliminar cuenta', // ok
            cancel: 'Cancelar', // ok
        },

        dashboardDescuentos: {
            discounts_published: 'Ofertas/cupones publicados', // ok
            no_discounts_published: 'Aun no tienes ofertas/cupones publicados', // ok
            publish_one: '¡Comienza a publicar una ya!', // ok
            title_seo: 'Descuentos | Jdescuentos', // ok
            description_seo: 'Descuentos que has publicado en Jdescuentos', // ok
        },

        dashboardGuardados: {
            discounts_saved: 'Ofertas/cupones guardados', // ok
            no_discounts_saved: 'Aún no tienes ofertas/cupones guardados', // ok
            title_seo: 'Guardados | Jdescuentos', // ok
            description_seo: 'Descuentos que has guardado en tu cuenta', // ok
        },

        dashboardIndex: {
            see: 'Ver', // ok
            recent_activity: 'Actividad reciente', // ok
            discounts_published: 'Ofertas/cupones publicados', // ok
            discounts_saved: 'Ofertas/cupones guardados', // ok
            chats: 'Mis chats', // ok
            lotteries: 'Mis sorteos', // ok
            settings: 'Ajustes', // ok
            title_seo: 'Tu dashboard | Jdescuentos', // ok
            description_seo: 'Tu dashboard de usuario', // ok
        },

        dashboardIndexMain: {
            member_since: 'Miembro desde:', // ok
            followers: 'Seguidores ', // ok
            ranking: 'Ranking de usuario', // ok
            settings: 'Ajustes de cuenta', // ok
            rewards: 'Recompensas', // ok
            stats: 'Estadísticas', // ok
            verified_user: 'Usuario verificado', // ok
            user_was_verified: 'Tu usuario ya ha sido verificado', // ok
            verified_description:
                // ok
                'Es un usuario que tiene la insignia de “Verificado” y lo consigue tras 25 ofertas/cupones aprobados y 50 comentarios aprobados. Se le debe enviar un correo electrónico para informarle y además se le informará por la mensajería y en notificaciones.',
            discounts: 'Cupones/ofertas', // ok
            comments: 'Comentarios', // ok
            account: 'Mi cuenta', // ok
            notifications: 'Notificaciones', // ok
            social_connection: 'Conexión social', // ok
            close_session: 'Cerrar sesión', // ok
            profile_not_found: 'Perfil de usuario no encontrado', // ok
            coins_available: 'Monedas disponibles', // ok
            coins_total: 'Monedas totales', // ok
            awards: 'Premios', // ok
            lotteries: 'Sorteos', // ok
            chats: 'Chats', // ok
            likes_sended: 'Likes enviados en ofertas y comentarios', // ok
            title_seo: 'Dashboard | Jdescuentos', // ok
            description_seo: 'Dashboard de usuario', // ok
            session_closed_forced: 'Sesión cerrada de manera forzada', // ok
        },

        dashboardMonedas: {
            coins: 'Monedas', // ok
            action: 'Acción', // ok
            user_not_verified: 'Usuario no verificado', // ok
            verified_user: 'Usuario verificado', // ok
            information: 'Información', // ok
            points: 'puntos', // ok
            coins_quantity: 'Monedas', // ok
            historical_events: 'Histórico de eventos', // ok
            date: 'Fecha', // ok
            received_coins: 'Monedas conseguidas', // ok
            no_coins_earned: 'Todavía no has ganado monedas', // ok
            faq: 'Preguntas frecuentes', // ok
            register_login: 'Registro y login', // ok
            action_1_time: 'Acción: 1 vez.', // ok
            comments: 'Comentarios', // ok
            action_5_time_per_day: 'Acción: 5 veces por día.', // ok
            report_expired_offer: 'Reportar oferta expirada', // ok
            action_5_time_per_day_approved: 'Acción: 5 veces por día (aprobado).', // ok
            offer_published: 'Oferta publicada', // ok
            action_1_time_per_day_if_approved: 'Acción: 1 vez por día (si es aprobado).', // ok
            coupon_published: 'Cupón publicado', // ok
            lottery_published: 'Sorteo publicado', // ok
            action_no_limits: 'Acción: sin límite.', // ok
            discount_reach_250: 'Oferta/cupón alcanza 250°', // ok
            discount_reach_500: 'Oferta/cupón alcanza 500°', // ok
            check_in_daily: 'Check-in diario', // ok
            action_1_time_per_day: 'Acción: 1 vez por día', // ok
            antiquity_1_month: 'Usuario alcanza antigüedad de 1 mes', // ok
            antiquity_6_month: 'Usuario alcanza antigüedad de 6 meses', // ok
            aniversary: 'Usuario aniversario', // ok
            action_1_time_anually: 'Acción: 1 vez anual.', // ok
            coins_are_free: '¿Las monedas son gratis de conseguir?', // ok
            coins_are_free_answer:
                'Sí, conseguir monedas es totalmente gratis y se consigue participando en la comunidad, accediendo, comentando y publicando ofertas, cupones o discusiones, entre otras muchas formas de conseguirlo.', // ok
            can_buy_coins: '¿Se pueden comprar monedas extra?', // ok
            can_buy_coins_answer:
                'No, al menos de momento. Pero si consigues que tu usuario sea verificado (nivel 1) conseguirás casi siempre el doble de monedas por cada acción que realices. Para ser usuario verificado solo necesitas tener 25 descuentos publicados y 50 comentarios publicados.', // ok
            how_can_use_coins: '¿Cómo puedo gastar mis monedas?', // ok
            how_can_use_coins_answer:
                'Cuando tengas monedas suficientes dirígete a la sección Premios y automáticamente verás los que hay disponibles para solicitarlo. Una vez solicitamos verificaremos y aprobaremos tu premio si cumples con los requisitos.', // ok
            title_seo: 'Monedas | Jdescuentos', // ok
            description_seo:
                'Conoce las monedas que te podemos ofrecer para conseguir descuentos en Jdescuentos',
        },

        dashboardPremios: {
            awards: 'Premios', // ok
            available_awards: 'Premios disponibles', // ok
            historical_awards: 'Histórico de premios ganados', // ok
            points: 'puntos', // ok
            redeem: 'Canjear premio', // ok
            no_prizes_available: 'En este momento no hay premios disponibles', // ok
            // ok
            come_back_later:
                'Vuelve más tarde para intentar canjear tus monedas por premios. En breve estarán disponibles.',
            coins: 'monedas', // ok
            award_redeemed: 'Premio canjeado', // ok
            no_prizes_achieved: 'Ningún premio conseguido aún', // ok
            // ok
            keep_farming:
                'Sigue consiguiendo monedas y aquí verás tus premios conseguidos muy pronto. Participa, comparte y comenta para ser de los mejores usuarios',
            title_seo: 'Premios | Jdescuentos', // ok
            description_seo: 'Premios disponibles en Jdescuentos', // ok
            redeem_success_awaiting: 'Premio canjeado, pendiente de aprobación', // ok
            close: 'Cerrar', // ok
            error_on_redeem: 'Error al canjear premio', // ok
        },

        dashboardSorteos: {
            my_lotteries: 'Mis sorteos', // ok
            ups: '¡Outs! al parecer aun no tienes sorteos', // ok
            start: '¡Comienza ya!', // ok
            title_seo: 'Sorteos | Jdescuentos', // ok
            description_seo: 'Sorteos que has publicado en Jdescuentos', // ok
        },

        eventosSlug: {
            featured_stores: 'Tiendas destacadas', // ok
            featured_discounts: 'Descuentos destacados', // ok
            no_discounts: 'No hay descuentos todavía activos, vuelve más tarde', // ok
            enjoy: 'Disfruta de TODOS los cupones y descuentos en', // ok
            in_stores: 'en estas tiendas',
            event_not_found: 'Este evento no fue encontrado', // ok
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente mas tarde', // ok
            home: 'Home', // ok
            events: 'Eventos', // ok
        },

        eventosIndex: {
            active_events: 'Eventos Activos', // ok
            no_active_events: 'Al parecer no hay ningún evento activo', // ok
            next_events: 'Próximos eventos', // ok
            not_events_found: 'No encontramos eventos disponibles para el próximo año', // ok
            missed_events: 'Eventos que te has perdido', // ok
            no_expired_events: 'Al parecer no hay eventos expirados', // ok
            no_discounts_active: 'No hay descuentos todavía activos, vuelve más tarde', // ok
            save: 'Ahorra con los cupones exclusivos de Jdescuentos', // ok
        },

        // eventosIndex: {
        //     thread: 'Temas',
        //     create_new_thread: 'Crear nuevo tema',
        //     categories: 'Categorías',
        //     popular_stores: 'Tiendas Populares',
        //     todo: 'Todo',
        //     home: 'Home',
        //     forums: 'Foros',
        // },

        foros: {
            threads: 'Temas', // ok
            create_new_forum: 'Crear nuevo tema', // ok
            forum: 'Foros', // ok
            popular_shops: 'Tiendas Populares', // ok
            home: 'Home', // ok
            categories: 'Categorias', // ok
        },

        forosSlug: {
            ago: 'Hace', // ok
            comments: 'Comentarios', // ok
            following: 'Siguiendo', // ok
            follow: 'Seguir', // ok
            more_relevant: 'Más relevantes', // ok
            more_recents: 'Más recientes', // ok
            no_comments_yet: 'Todavía no hay comentarios', // ok
            comment_first: 'Comenta el primero y obtén me gustas en tu comentario', // ok
            discussions: 'Discusiones', // ok
            see_more: 'Ver más', // ok
            thread_not_found: 'Este hilo no fue encontrado', // ok
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente mas tarde', // ok
            years: 'años', // ok
            months: 'meses', // ok
            weeks: 'semanas', // ok
            days: 'días', // ok
            hours: 'horas', // ok
            minutes: 'minutos', // ok
            seconds: 'segundos', // ok
            see_more_relevant_comments: 'Ver comentarios más relevantes', // ok
            see_all_comments: 'Ver todos los comentarios, los comentarios más recientes se muestran primero', // ok
            report: 'Reportar', // ok
            why_report: '¿Por qué lo reportas?', // ok
            spam_ofensive: 'Spam/Ofensivo', // ok
            duplicated: 'Duplicado', // ok
            offer_published: 'Ya esta la oferta publicada', // ok
            refered_links: 'Enlaces referidos', // ok
            other: 'Otro', // ok
            home: 'Home', // ok
            forums: 'Foros', // ok
            new: 'Nuevo', // ok
            popular: 'Popular', // ok
            hide: 'Ocultar', // ok
            published_comments: 'Comentario publicado de manera exitosa', // ok
            pending_comment: 'Comentario pendiente de aprobación', // ok
            comment_error: 'Comentario no se pudo crear', // ok
            okay: 'De acuerdo', // ok
            report_success: 'Su reporte fue enviado exitosamente', // ok
            close: 'Cerrar', // ok
            report_error: 'No se pudo realizar el reporte', // ok
        },

        mensajeria: {
            inbox: 'Inbox', // ok
            send_message: 'Enviar mensaje', // ok
            no_readed_conversations: 'Aún no hay conversaciones iniciadas', // ok
            load_more_messages: 'Cargar más mensajes', // ok
            write_your_message: 'Escribe tu mensaje aquí', // ok
            send: 'Enviar', // ok
            to_start: 'Para empezar a conversar, selecciona un chat o inicia uno', // ok
            title_seo: 'Mensajería | Jdescuentos', // ok
            description_seo: 'Página para ver y enviar mensajes entre usuarios', // ok
            delete: 'Eliminar', // ok
            delete_conversation: 'Eliminar esta conversación', // ok
            want_delete_conversation: 'Quieres eliminar esta conversación', // ok
            conversation_deleted: 'Conversacion eliminada', // ok
            hide: 'Ocultar', // ok
            error: 'Ocurrió un error al eliminar la conversación', // ok
            cancel: 'Cancelar', // ok
            mute_conversation: 'notificaciones en esta conversación', // ok
            unmute_conversation: 'Desilenciar notificaciones en esta conversación',
            want: 'Quieres', // ok
            this_conversation: 'esta conversación?', // ok
            conversation_muted: 'Conversación silenciada', // ok
            error_on_conversation_mute: 'Ocurrió un error al silenciar la conversación', // ok
            unmute_may: 'Desilenciar', // ok
            mute_may: 'Silenciar', // ok
            unmute: 'desilenciar', // ok
            mute: 'silenciar', // ok
            unexpected_error: 'Ocurrió un error inesperado, intente nuevamente en unos minutos', // ok
            error_on_send_message: 'Ocurrió un error al enviar el mensaje', // ok
        },

        novedadesSlug: {
            last_novelties: 'Últimas novedades', // ok
            novelty_not_found: 'Esta novedad no fue encontrada', // ok
            unexpected_error: 'Sucedió un problema inesperado, por favor intente nuevamente mas tarde', // ok
            home: 'Home', // ok
            novelties: 'Novedades', // ok
        },

        novedades: {
            novelties: 'Novedades', // ok
            no_novelties: 'Aún no hay ninguna novedad disponible', // ok
        },

        ofertas: {
            featured_offers: 'Ofertas destacados', // ok
            popular_brands: 'Marcas destacadas', // ok
            description: 'Description', // ok
            popular_stores: 'Tiendas Populares', // ok
            populars: 'Populares', // ok
            news: 'Nuevos', // ok
            commented: 'Comentados', // ok
            home: 'Home', // ok
            offers: 'Ofertas', // ok
        },

        acercaDe: {
            meet: 'Conoce MEGAdescuentos, la plataforma nº1 en México',
            us: 'Nosotros',
            hi: 'Hola a todos, soy Jotah, Admin de MEGAdescuentos México. Junto con mi equipo hemos creado esta plataforma como alternativa a otras que existen, porque consideramos que una plataforma de este tipo debe recompensar a los usuarios por su actividad y nosotros lo haremos. Además, haremos con frecuencia acciones en redes sociales y también vamos a seguir desarrollando funcionalidades a MEGAdescuentos con secciones VIP, eventos, nuevas formas de ver ofertas/cupones y mucho más. Te recomendamos que seas usuario activo porque los vamos a recompensar con un montón de sorpresas. Regístrate y participa, no te arrepentirás. Cualquier cosa puedes contactar conmigo por Instagram @jotahmega',
            how_works: '¡Cómo funciona!',
            share_discounts: 'Comparte ofertas, cupones o debates con la comunidad',
            participate: 'Participa en conversaciones e interactúa con los demás miembros',
            every_action: 'Por cada acción ganarás monedas que podrás canjear por premios',
            join_us: 'Únete a MEGAdescuentos',
            our_vision: 'Nuestra visión',

            transform:
                'Queremos transformar cómo encontrar descuentos en México y en Latinoamérica con una plataforma completa, en la que vamos a incluir tiendas en línea y pequeños negocios y llegar a colaboraciones con marcas para que puedan  ofrecernos las mejores ofertas y cupones.',

            together:
                'Junto con nuestro equipo y ustedes, los miembros, conseguiremos la comunidad más grande de Latinoamérica de descuentos. Y ustedes se verán recompensados con premios y regalos, siempre que participen e interactúen.',
            our_team: 'Nuestro equipo',

            team_description:
                'El equipo de MEGAdescuentos como imaginarás es muy grande para mantener la plataforma a nivel técnico en todas las áreas, requiere también de personal de diseño web y un gran equipo de editores y moderadores que son el equipo de contenidos, que son los que estarán pendientes de revisar todo el contenido, comentarios y disputas. Sin todo el equipo no sería posible MEGAdescuentos :)',
        },

        // Página <Sorteos>:
        // Todo listo
        // Revisa tu correo y sigue las instrucciones
        // Cambiar contraseña | MEGAdescuentos
        // Página para cambiar la contraseña

        contacto: {
            contact: 'Contáctanos', // ok
            title: 'Título', // ok
            details: 'Introduce el asunto de tu consulta', // ok
            affair: 'Asunto', // ok
            name: 'Nombre', // ok
            required: 'Este campo es requerido', // ok
            email_address: 'Correo eléctronico', // ok
            message: 'Mensaje', // ok
            remember: 'Recuerda que te estás poniendo en contacto con Jdescuentos y no con alguna tienda', // ok
            send_message: 'Enviar mensaje', // ok
            why_contact: '¿Sobre qué asunto nos contactas?', // ok
            share_offer: 'Compartir oferta', // ok
            account: 'Mi Cuenta', // ok
            comercial: 'Comercial', // ok
            suggests: 'Sugerencias', // ok
            complaints_legal: 'Quejas y Legal', // ok
            others: 'Otros', // ok
            message_error: 'Sucedió un error, por favor, intentelo nuevamente mas tarde', // ok
            message_success: 'Tu mensaje ha sido enviado correctamente', // ok
        },

        contrasenaActualizadaExitosamente: {
            success: 'Contraseña actualizada correctamente', // ok
            enjoy: 'Ahora disfruta de las ofertas/cupones en Jdescuentos', // ok
            see_coupons: 'Ver cupones', // ok
            see_offers: 'Ver ofertas', // ok
            redirect: 'Serás redirigido al login en segundos', // ok
            title_seo: 'Contraseña actualizada exitosamente | Jdescuentos', // ok
            description_seo: 'Página de contraseña actualizada exitosamente', // ok
        },

        expirados: {
            lost: 'Mira lo que te has perdido', // ok
            expired_recently: 'Ofertas y cupones que expiraron recientemente', // ok
            no_discounts_expired: 'No hay ofertas/cupones expirados', // ok
            found_discounts: '¡No todo es gris! Encuentra ofertas y cupones en otras búsquedas', // ok
            featured_stores: 'Tiendas Populares', // ok
            coupons: 'Mega cupones', // ok
            offers: 'MEGA ofertas', // ok
            lotteries: 'Sorteos', // ok
            categories: 'Categorías', // ok
            brands: 'Marcas', // ok
            register: 'Registrarse', // ok
            popular: 'Populares', // ok
            news: 'Nuevos', // ok
            commented: 'Comentados', // ok
            all_coupons:
                'Todos los cupones & ofertas expirados recientemente en :month :year. Por si te has perdido algo.', // ok
        },

        extensionDesinstalada: {
            sorry: 'Sentimos verte partir', // ok
            mistake: '¿Desinstalaste por error?', // ok
            reinstall: 'Reinstalar extensión', // ok
            why: 'Si lo hizo a propósito, ayúdenos a mejorar indicándonos por qué desinstalo Jdescuentos', // ok
            select: 'Selecciona las casillas que correspondan', // ok
            think: 'Dinos que piensas...', // ok
            other_comments: '¿Qué otros comentarios tienes para nosotros?', // ok
            send: 'Enviar', // ok
            by_mistake: 'Lo instalé por error.', // ok
            codes_not_work: 'Los códigos no funcionaron.', // ok
            md_not_work: 'Jdescuentos no funciona donde compro.', // ok
            md_confuse: 'Encontré a Jdescuentos algo confuso.', // ok
            savings_useless: 'Los ahorros no fueron útiles.', // ok
            other: 'Otro.', // ok
            title_seo: 'Extensión desinstalada | Jdescuentos', // ok
            description_seo: 'Página de extensión desinstalada', // ok
            error: 'Error al enviar datos', // ok
            pick_one: 'Debes seleccionar al menos una opción o darnos un comentario.', // ok
            close: 'Cerrar', // ok
        },

        extension: {
            amazing_discounts: 'Descuentos increíbles y 100% gratis con la extensión de Jdescuentos', // ok
            find_codes:
                '¿Te gusta comprar en línea? Encontrar códigos y ofertas para sus marcas favoritas ahora es más fácil y gratis.', // ok
            install: 'Instalar ahora gratis', // ok
            free: 'Es Gratis', // ok
            free_no_cost: '100% gratuita y sin costo, además de actualizaciones frecuentes.', // ok
            apply: 'Aplica automáticamente', // ok
            we_tell_auto: 'Te avisaremos de forma automática cuando encontremos cupones & ofertas.', // ok
            register_not_required: 'No requiere registro', // ok
            install_and_go: 'Solo instalar y ya. No tienes que registrarte ni perder tiempo.', // ok
            best_extension: 'La mejor extensión de ofertas y cupones', // ok
            developing:
                'Estamos desarrollando la mejor extensión de navegador de cupones & ofertas de Chile, para que puedas ahorrar en tus marcas favoritas y no tengas que perder tiempo buscando descuentos que luego no funcionan. Instala hoy y comienza a ahorrar.', // ok
            find_best_savings: 'Encuentra los mejores ahorros', // ok
            brands: 'Rappi, Linio, AliExpress, Walmart y mucho más', // ok
            // ok
            best_brands:
                'Las mejores marcas de Chile se encuentran en Jdescuentos, con los mejores cupones y ofertas. Encuentra promociones para marcas como Nike, Uber Eats, AliExpress o Temu, entre otros muchos. Más de 250 marcas ya están disponibles ¿Te lo vas a perder?', // ok
            get_now: '¡Consíguelo ahora!', // ok
            download_extension: 'Descargar Extensión', // ok
            extension_free:
                'La extensión es completamente gratuita. No hay suscripciones, complementos ni  sorpresas desagradables. Pruébalo hoy.', // ok
            answers_here: 'Aquí las respuestas a tus dudas...', // ok
            is_extension_free: '¿La extensión es gratuita?', // ok
            is_extension_free_answer:
                'Sí, 100% gratuita y sin registro. Solo instala la extensión y empieza a ahorrar en tus tiendas favoritas.', // ok
            how_install_extension: '¿Cómo instalar la extensión de Jdescuentos?', // ok
            how_install_extension_answer:
                'Es muy fácil. Solo tienes que buscar Jdescuentos en tu el store de Google Chrome o Firefox e instalarla.', // ok
            how_know_works: '¿Cómo puedo saber si funciona bien?', // ok
            how_know_works_answer:
                'Cuando visites una de las tiendas asociadas automáticamente te mostraremos la extensión y empezarás a ver cupones. O si lo abres manualmente te dirá si hay ofertas en esa tienda en cuestión.', // ok
            what_shops_discounts: '¿Qué tiendas tienen descuentos?', // ok
            what_shops_discounts_answer:
                'Tenemos cupones & ofertas para las principales tiendas como Amazon, Temu, AliExpress o Shein, entre otras muchas.', // ok
            how_remove: '¿Cómo se elimina la extensión?', // ok
            how_remove_answer:
                'No te recomendamos desinstalarla porque perderás muchos ahorros en compras en línea. Pero si quieres hacerlo es clic derecho y seleccionar “Desinstalar”', // ok
            works_mobile: '¿Funciona en móvil?', // ok
            works_mobile_answer:
                'Estamos trabajando para que funcione en Safari. Te notificaremos por las Novevades del sitio.', // ok
            title_seo: 'Extensión | Jdescuentos', // ok
            description_seo: 'Página de extensión', // ok
        },

        gratis: {
            products: 'Productos y servicios', // ok
            free: 'GRATIS', // ok
            in_mexico: 'en Chile', // ok
            description: 'Descripción', // ok
            featured_stores: 'Tiendas Populares', // ok
            populars: 'Populares', // ok
            news: 'Nuevos', // ok
            home: 'Home', // ok
            stores: 'Tiendas', // ok
        },

        inicioSinContrasena: {
            login_without_password: 'Inicio de sesión sin contraseña', // ok
            title_seo: 'Iniciar sin contraseña | Jdescuentos', // ok
            description_seo: 'Página de inicio de sesión sin contraseña', // ok
        },

        login: {
            login: 'Inicia sesión', // ok
            no_account: '¿No tienes una cuenta?', // ok
            register_here: 'Regístrate aquí', // ok
        },

        notificaciones: {
            notifications: 'Notificaciones', // ok
            no_readed: 'No leídas', // ok
            no_unreaded: 'Por ahora no tienes notificaciones sin leer', // ok
            readed: 'Leídas', // ok
            no_notifications: 'No hay notificaciones', // ok
            no_notifications_by_now: 'Por ahora no tienes notificaciones', // ok
            title_seo: 'Notificaciones | Jdescuentos', // ok
            description_seo: 'Página para ver las notificaciones de tu cuenta', // ok
            error: 'Hubo un error al cargar las notificaciones', // ok
        },

        nuevaContrasena: {
            new_password: 'Nueva contraseña', // ok
            write_new_password: 'Escribe tu nueva contraseña', // ok
            repeat_new_password: 'Repetir contraseña', // ok
            change_password: 'Cambiar contraseña', // ok
            title_seo: 'Nueva contraseña | Jdescuentos', // ok
            description_seo: 'Página de generación de nueva contraseña', // ok
            close: 'Cerrar', // ok
            incorrect_fields: 'Hay campos incorrectos, sigue las instrucciones.', // ok
            token_failed: 'El token es incorrecto o ha caducado', // ok
        },

        olvideMiContrasena: {
            forgot_password: '¿Has olvidado tu contraseña?', // ok
            title_seo: 'Olvidé mi contraseña | Jdescuentos', // ok
            description_seo: 'Página de recuperación de contraseña', // ok
        },

        registro: {
            register: 'Regístrate', // ok
            have_account: '¿Tienes una cuenta?', // ok
            login_here: 'Inicia sesión aquí', // ok
        },

        search: {
            results: 'Resultados para', // ok
            this: 'este', // ok
            sorry: 'Lo siento! No hemos encontrado resultados en tu búsqueda', // ok
            meanwhile: 'Mientras puedes revisar los mejores cupones aquí', // ok
            what_are_looking: '¿Qué estás buscando?', // ok
            easier: 'Facilita tu búsqueda eligiendo una de estas opciones', // ok
            related: 'relacionadas con tu búsqueda', // ok
            offer_and_coupons: 'Ofertas y cupones', // ok
            featured: 'destacados', // ok
            recommend: 'También te recomendamos estas marcas donde encontraras las mejores Ofertas y Cupones', // ok
            results_for: 'Resultados de búsqueda para', // ok
            of: 'de', // ok
            todo: 'Todo', // ok
            offers: 'Ofertas', // ok
            coupons: 'Cupones', // ok
            brands: 'Marcas', // ok
        },

        verificarCuenta: {
            request_failed: 'Su solicitud no puede ser procesada correctamente', // ok
            redirect: 'Serás redirigido a inicio en segundos:', // ok
            verified_success: 'Cuenta verificada exitosamente', // ok
            enjoy: 'Ahora disfruta de las ofertas/cupones en Jdescuentos', // ok
            go_home: 'Ir a inicio', // ok
            go_profile: 'Ir a mi perfil', // ok
            error_on_validate: 'No se pudo verificar su cuenta', // ok
            code_not_valid: 'Código de verificación no válido o expirado', // ok
            go_to_settings: 'Ir a ajustes', // ok
            contact_support: 'Contactar el soporte', // ok
            verify_error: 'Ocurrió un error al intentar verificar tu cuenta', // ok
            title_seo: 'Verificar cuenta | Jdescuentos', // ok
            description_seo: 'Página para la verificación de la cuenta de usuario', // ok
        },
    },
}

export default ES
