import { default as discount_45maskj9HVw2dGOTMeta } from "~/views/discount-mask.vue?macro=true";
import { default as store_45maskrrOfhZN0pkMeta } from "~/views/store-mask.vue?macro=true";
import { default as store_45landing6Fs2zRsHqVMeta } from "~/views/store-landing.vue?macro=true";
import { default as homepage_45smallAhlhoSsfK4Meta } from "~/views/homepage-small.vue?macro=true";
import { default as store_45bridgeRyrhTqaIb2Meta } from "~/views/store-bridge.vue?macro=true";
import { default as category_45bridgepUYmiO2rTXMeta } from "~/views/category-bridge.vue?macro=true";
import { default as discount_45detailsAu0njxb3NgMeta } from "~/views/discount-details.vue?macro=true";
import { default as acerca_45de_45jdescuentosAJxd90WbGkMeta } from "~/views/acerca-de-jdescuentos.vue?macro=true";
import { default as aviso_45legal27GreimsDMMeta } from "~/views/aviso-legal.vue?macro=true";
import { default as ayuda6cYlYHtpPuMeta } from "~/views/ayuda.vue?macro=true";
import { default as indexPUvMN4GjS1Meta } from "~/views/blog/index.vue?macro=true";
import { default as contactouEjPCNMSn5Meta } from "~/views/contacto.vue?macro=true";
import { default as cookiesZdG9x55enXMeta } from "~/views/cookies.vue?macro=true";
import { default as cuponescYUW8ZiMjPMeta } from "~/views/cupones.vue?macro=true";
import { default as indexrC7BL1lmx3Meta } from "~/views/eventos/index.vue?macro=true";
import { default as expirados2cVA3uut0qMeta } from "~/views/expirados.vue?macro=true";
import { default as faqknATEkiae7Meta } from "~/views/faq.vue?macro=true";
import { default as gratisMnPRtxOIQDMeta } from "~/views/gratis.vue?macro=true";
import { default as ofertas1iv4IpaQurMeta } from "~/views/ofertas.vue?macro=true";
import { default as politicas_45de_45privacidadnm6yFCqQsmMeta } from "~/views/politicas-de-privacidad.vue?macro=true";
import { default as prensajve8ab1rGMMeta } from "~/views/prensa.vue?macro=true";
import { default as reglas_45de_45comunidadOV9dTNAqzDMeta } from "~/views/reglas-de-comunidad.vue?macro=true";
import { default as searchJXWnylL0IyMeta } from "~/views/search.vue?macro=true";
import { default as indexw4U5mpNMlrMeta } from "~/views/blog/[category]/index.vue?macro=true";
import { default as _91slug_93Rh3p8BZ1JvMeta } from "~/views/eventos/[slug].vue?macro=true";
import { default as _91article_930CtyZBH1FtMeta } from "~/views/blog/[category]/[article].vue?macro=true";
import { default as indexFNYRkGxMcmMeta } from "~/views/sitemap/index.vue?macro=true";
import { default as component_45stub1t2WxpUmsFMeta } from "/github/workspace/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub1t2WxpUmsF } from "/github/workspace/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "open-slug",
    path: "/open/:slug",
    meta: discount_45maskj9HVw2dGOTMeta || {},
    component: () => import("~/views/discount-mask.vue").then(m => m.default || m)
  },
  {
    name: "go-id",
    path: "/go/:id",
    component: () => import("~/views/discount-mask.vue").then(m => m.default || m)
  },
  {
    name: "go-cupones-descuento-id",
    path: "/go/cupones-descuento/:id",
    meta: store_45maskrrOfhZN0pkMeta || {},
    component: () => import("~/views/store-mask.vue").then(m => m.default || m)
  },
  {
    name: "landing-slug",
    path: "/landing/:slug",
    meta: store_45landing6Fs2zRsHqVMeta || {},
    component: () => import("~/views/store-landing.vue").then(m => m.default || m)
  },
  {
    name: "homepage",
    path: "/",
    component: () => import("~/views/homepage-small.vue").then(m => m.default || m)
  },
  {
    name: "cupones-descuento-slug",
    path: "/cupones-descuento/:slug",
    component: () => import("~/views/store-bridge.vue").then(m => m.default || m)
  },
  {
    name: "cupones-descuento",
    path: "/cupones-descuento",
    component: () => import("~/views/store-bridge.vue").then(m => m.default || m)
  },
  {
    name: "promociones-slug",
    path: "/promociones/:slug",
    component: () => import("~/views/category-bridge.vue").then(m => m.default || m)
  },
  {
    name: "promociones",
    path: "/promociones",
    component: () => import("~/views/category-bridge.vue").then(m => m.default || m)
  },
  {
    name: "cupones-slug",
    path: "/cupones/:slug",
    component: () => import("~/views/discount-details.vue").then(m => m.default || m)
  },
  {
    name: "ofertas-slug",
    path: "/ofertas/:slug",
    component: () => import("~/views/discount-details.vue").then(m => m.default || m)
  },
  {
    name: acerca_45de_45jdescuentosAJxd90WbGkMeta?.name,
    path: "/acerca-de-nosotros",
    component: () => import("~/views/acerca-de-jdescuentos.vue").then(m => m.default || m)
  },
  {
    name: aviso_45legal27GreimsDMMeta?.name,
    path: "/terminos-de-uso",
    component: () => import("~/views/aviso-legal.vue").then(m => m.default || m)
  },
  {
    name: ayuda6cYlYHtpPuMeta?.name,
    path: "/ayuda",
    component: () => import("~/views/ayuda.vue").then(m => m.default || m)
  },
  {
    name: indexPUvMN4GjS1Meta?.name,
    path: "/blog",
    component: () => import("~/views/blog/index.vue").then(m => m.default || m)
  },
  {
    name: category_45bridgepUYmiO2rTXMeta?.name,
    path: "/promociones",
    component: () => import("~/views/category-bridge.vue").then(m => m.default || m)
  },
  {
    name: contactouEjPCNMSn5Meta?.name,
    path: "/contacto",
    component: () => import("~/views/contacto.vue").then(m => m.default || m)
  },
  {
    name: cookiesZdG9x55enXMeta?.name,
    path: "/cookies",
    component: () => import("~/views/cookies.vue").then(m => m.default || m)
  },
  {
    name: cuponescYUW8ZiMjPMeta?.name,
    path: "/cupones",
    component: () => import("~/views/cupones.vue").then(m => m.default || m)
  },
  {
    name: indexrC7BL1lmx3Meta?.name,
    path: "/eventos",
    component: () => import("~/views/eventos/index.vue").then(m => m.default || m)
  },
  {
    name: expirados2cVA3uut0qMeta?.name,
    path: "/expirados",
    component: () => import("~/views/expirados.vue").then(m => m.default || m)
  },
  {
    name: faqknATEkiae7Meta?.name,
    path: "/faq",
    component: () => import("~/views/faq.vue").then(m => m.default || m)
  },
  {
    name: gratisMnPRtxOIQDMeta?.name,
    path: "/gratis",
    component: () => import("~/views/gratis.vue").then(m => m.default || m)
  },
  {
    name: store_45bridgeRyrhTqaIb2Meta?.name,
    path: "/cupones-descuento",
    component: () => import("~/views/store-bridge.vue").then(m => m.default || m)
  },
  {
    name: ofertas1iv4IpaQurMeta?.name,
    path: "/ofertas",
    component: () => import("~/views/ofertas.vue").then(m => m.default || m)
  },
  {
    name: politicas_45de_45privacidadnm6yFCqQsmMeta?.name,
    path: "/politica-de-privacidad",
    component: () => import("~/views/politicas-de-privacidad.vue").then(m => m.default || m)
  },
  {
    name: prensajve8ab1rGMMeta?.name,
    path: "/prensa",
    component: () => import("~/views/prensa.vue").then(m => m.default || m)
  },
  {
    name: reglas_45de_45comunidadOV9dTNAqzDMeta?.name,
    path: "/reglas-del-sitio",
    component: () => import("~/views/reglas-de-comunidad.vue").then(m => m.default || m)
  },
  {
    name: searchJXWnylL0IyMeta?.name,
    path: "/search",
    component: () => import("~/views/search.vue").then(m => m.default || m)
  },
  {
    name: indexw4U5mpNMlrMeta?.name,
    path: "/blog/:category",
    component: () => import("~/views/blog/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: category_45bridgepUYmiO2rTXMeta?.name,
    path: "/promociones/:slug",
    component: () => import("~/views/category-bridge.vue").then(m => m.default || m)
  },
  {
    name: discount_45detailsAu0njxb3NgMeta?.name,
    path: "/cupones/:slug",
    component: () => import("~/views/discount-details.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Rh3p8BZ1JvMeta?.name,
    path: "/eventos/:slug",
    component: () => import("~/views/eventos/[slug].vue").then(m => m.default || m)
  },
  {
    name: store_45bridgeRyrhTqaIb2Meta?.name,
    path: "/cupones-descuento/:slug",
    component: () => import("~/views/store-bridge.vue").then(m => m.default || m)
  },
  {
    name: discount_45detailsAu0njxb3NgMeta?.name,
    path: "/ofertas/:slug",
    component: () => import("~/views/discount-details.vue").then(m => m.default || m)
  },
  {
    name: _91article_930CtyZBH1FtMeta?.name,
    path: "/blog/:category/:article",
    component: () => import("~/views/blog/[category]/[article].vue").then(m => m.default || m)
  },
  {
    name: indexFNYRkGxMcmMeta?.name,
    path: "/sitemap",
    component: () => import("~/views/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/extension",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/extension-desinstalada",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/discusiones",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/cambiar-contrasena",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/comentados",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/compartir",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/contrasena-actualizada-exitosamente",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/inicio-sin-contrasena",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/login",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/nueva-contrasena",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/recuperar-password",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/registro",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/verificar-cuenta",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/dashboard",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/dashboard/:pathMatch(.*)",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/compartir/:pathMatch(.*)",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/discusiones/:pathMatch(.*)",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/mensajes",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/notificaciones",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/novedades",
    component: component_45stub1t2WxpUmsF
  },
  {
    name: component_45stub1t2WxpUmsFMeta?.name,
    path: "/novedades/:pathMatch(.*)",
    component: component_45stub1t2WxpUmsF
  }
]